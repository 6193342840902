
<div *ngIf="isMobile === false" class="poll-item" (click)=actionVote($event)>
  <label class="resolution-label">
    <div id="number-holder">
      <!-- <div *ngIf="this.resolutionImage != undefined" class="option-image-wrapper"> -->
      <div class="option-image-wrapper" id="image-wrapper-{{index}}">
        <img id="resolution-{{index}}" class="option-image" src="#" alt="{{resolution.text}}">
      </div>
      <div
        [ngClass]="isUnanswered ? 'circle-unanswered' : 'circle'" id="wrapper-{{index}}">
        <span class="resolution-index" id="{{index + 1}}">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder">
      <span *ngIf="this.resolution != null && this.resolution.links[0] != null && this.resolution.links[0] != '' else noLink" class="poll-item-question">
        <a href="{{replaceURLs(this.resolution.links[0])}}" target="_blank">{{resolution.text === '' ? '?' : resolution.text}}</a>
      </span>
      <ng-template #noLink>
        <span class="poll-item-question">
          {{resolution.text === '' ? '?' : resolution.text}}
        </span>
      </ng-template>
    </div>
    <div>
      <img src={{imageAsset}} class="helper-image">
    </div>
  </label>
</div>
<div *ngIf="isMobile === true" class="poll-item" (click)=actionVote($event)>
  <label class="resolution-label">
    <div id="number-holder">
      <div class="option-image-wrapper" id="image-wrapper-{{index}}">
        <img id="resolution-{{index}}" class="option-image" src="#" alt="{{resolution.text}}">
      </div>
      <div
        [ngClass]="isUnanswered ? 'circle-unanswered' : 'circle'" id="wrapper-{{index}}">
        <span class="resolution-index" id="{{index + 1}}">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder-mob">
      <span *ngIf="this.resolution != null && this.resolution.links[0] != null && this.resolution.links[0] != '' else noLink" class="poll-item-question">
        <a href="{{replaceURLs(this.resolution.links[0])}}" target="_blank">{{resolution.text === '' ? '?' : resolution.text}}</a>
      </span>
      <ng-template #noLink>
        <span class="poll-item-question">
          {{resolution.text === '' ? '?' : resolution.text}}
        </span>
      </ng-template>
    </div>
    <img src={{imageAsset}} class="helper-image">
  </label>
</div>

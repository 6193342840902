<!-- <body class="description-long">{{question === '' ? '?' : question}}</body> -->
<div *ngFor="let result of results let index = index">
  <div class="separator"></div>
  <div class="election-title">
    <span class="index">{{index}} </span><span class="title">{{electionNames[index]}}</span>
  </div>
  <div *ngFor="let tmp of result">
    <app-poll-results-result [result]="tmp">
    </app-poll-results-result>
  </div>
</div>

import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ReceivedQuestion} from '../../../shared/model/poll.model';

@Component({
  selector: 'app-poll-election-item',
  templateUrl: './poll-election-item.component.html',
  styleUrls: ['./../../poll-detail-resolutions-list/poll-resolution-item/poll-resolution-item.component.less'],
})
export class PollElectionItemComponent implements OnInit{

  @Input() election: ReceivedQuestion;
  @Input() isMobile: boolean;
  @Input() index: number;
  @Input() votedData: number[];
  @Output() selected = new EventEmitter<number>();
  imageAsset = 'assets/fleche_resolution_orange.svg';
  isUnanswered = true;


  constructor() {
  }

  replaceURLs(message: string) {
    if (!message) { return; }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return hyperlink;
      });
  }

  ngOnInit(): void {
    this.isUnanswered = this.votedData.length === 0;
    if (!this.isUnanswered && this.election.minNumberOfVote != null && this.election.minNumberOfVote > this.votedData.length)
      { this.isUnanswered = true; }
  }

  actionVote() {
    window.scrollTo(0, 0);
    this.selected.emit(this.index);
  }
}

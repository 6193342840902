
<div [ngClass]="isMobile == true ? 'resolutions-holder-mob' : 'resolutions-holder'">
  <h3 id="resolutions-header">
    {{electionTitle}}
  </h3>
  <app-poll-corporate-details-item *ngFor="let question of questions; let i = index" [index]="i"
    [isMobile]="isMobile" [choice]="question" (selected)=getSelection($event) [answer]="localAnswers[i]">
  </app-poll-corporate-details-item>
</div>
<app-poll-vote-footer
      (selected)="choiceConfirmed($event)" [validButton]="isValidList" [twoButtons]="true">
</app-poll-vote-footer>

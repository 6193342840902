<div id="page-container">
    <div class="fullScreen">
      <img src="assets/nav-logo.svg" id="logo">
    </div>
    <div class="login-holder">
      <div class="safe-area">
        <h1 class="connexion">Récupérer mon invitation à voter</h1>
        <body class="description">Votre e-mail</body>
        <input type="text" (keyup)="onUser($event)">
        <div class="separator"></div>
        <div class="connexion-button" (click)="login()">
          <body class="connexion-button-title">Recevoir mon invitation</body>
        </div>
      </div>
    <div>
</div>
<div *ngIf="isError">
  <app-error
    (isClosed)="dismissError()"
    [errorMessage]="errorMessage"
    [isMobile]="isMobile">
  </app-error>
</div>

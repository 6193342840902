import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssessorComponentComponent } from './assesor-component/assessor-component.component';
import {PollResultsComponent} from './poll-results/poll-results.component';
import {RecoveryComponent} from './key-recover/recovery.component';
import {DynamicDisplayComponent} from './dynamic-display/dynamic-display.component';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';


const routes: Routes = [
  {
    path: 'poll/:id/results',
    component: PollResultsComponent
  },
  {
    path: 'poll/:id/manage',
    component: AssessorComponentComponent
  },
  {
    path: 'poll/recover',
    component: RecoveryComponent
  },
  {
    path: '**',
    component: DynamicDisplayComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../firebase';
import { ApiService } from "../shared/api.service";
import { VotingEvent } from '../shared/model/poll.model';
import { Common } from '../shared/common';


/**
 * Composant de test pemettant de mettre en place un affcihage dynamique.
 * ==> Il devra correspondre à votre composant de VOTE par exemple.
 */
@Component({
  selector: 'app-dynamic-display',
  templateUrl: './dynamic-display.component.html',
  styleUrls: ['./dynamic-display.component.less']
})
export class DynamicDisplayComponent implements OnInit {
  isMobile: boolean;
  lastname: any;
  firstname: any;
  pollId: string;
  privateKey: string;
  email: any;
  isClose: boolean = false;
  votingType: string;
  isLive: boolean;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private common: Common
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.pollId = params.electionId;
    });
    this.isMobile = this.common.checkIfMobile();
    this.route.queryParams.subscribe((params) => {
      this.pollId = params.electionId;
      this.privateKey = params.key;
    });

    this.apiService.getVoterName(this.pollId, this.privateKey).subscribe((res) => {
      this.firstname = res["firstname"]
      this.lastname = res["lastname"]
      this.email = res["email"]
    }, err => {
      this.isClose = err.error.error.localeCompare("Election is closed") == 0
    })
  }
}

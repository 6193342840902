<div class="form-holder" *ngIf="isResolution === false && !calculating && type !== 'list'">
  <div class="safe-area">
    <p>{{electionNames}}</p>
    <app-poll-results-list
      [electionNames]="electionNames" [results]="resultsArray">
    </app-poll-results-list>
  </div>
</div>

<div *ngIf="isResolution === true  && !calculating" class="form-holder">
  <div class="safe-area">
    <app-poll-results-resolution-list
      *ngFor="let result of resolutionResults; let i = index"
      [index]="i" [result]="result">
    </app-poll-results-resolution-list>
  </div>
</div>

  <!-- <div class="separator"></div> -->
    <!-- <h2 class="title">{{'POLL-RESULTS.HEADER' | translate}}</h2> -->
    <div *ngFor="let result of listResults; let i = index" class="form-holder">
      <div class="safe-area">
        <h2 *ngIf="!calculating" class="title">{{electionNames[i]}}</h2>
        <div class="blockchain-container" *ngIf="type == 'list'">
          <a class="blockchain-link" href="{{blockchainUrl}}" target="_blank" rel="noopener noreferrer"><img alt="globe" src="assets/global.svg" /> {{'POLL-DETAIL.POLL-BLOCKCHAIN' | translate}}</a>
        </div>
        <app-list-results-list *ngIf="isResolution === false && !calculating && type === 'list'"
        [results]="listResults[i]">
        </app-list-results-list>
      </div>
    </div>
<!-- </div> -->

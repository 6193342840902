import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Choice} from './../../../shared/model/poll.model';

@Component({
  selector: 'app-poll-resolution-choices-list',
  templateUrl: './poll-resolution-choices-list.component.html',
  styleUrls: ['./poll-resolution-choices-list.component.less'],
})
export class PollResolutionChoicesListComponent {

  @Input() choices: Choice[];
  @Input() isMobile: boolean;
  @Output() selected = new EventEmitter<Choice>();

  preselectChoice(event: Choice) {
    this.selected.emit(event);
  }

  ngOnInit() {
    // let video = document.getElementById("video-calls-container")
    // video.style.display = "none"
  }
}

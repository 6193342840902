// FAKE GMAIL \\
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getStorage } from "firebase/storage"
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyC-0LXZzU0K_RFo-qo-_c5BYkIn3aWOTZo",
//   authDomain: "v8te-a3df4.firebaseapp.com",
//   projectId: "v8te-a3df4",
//   storageBucket: "v8te-a3df4.appspot.com",
//   messagingSenderId: "993994476830",
//   appId: "1:993994476830:web:c8e3fe068834eee00b4d0f",
//   measurementId: "G-3N5K6GK1Q2"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const storage = getStorage(app)


// V8TE GMAIL \\
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA_qjvV6KKJD8aycHSFhN449tZQrQjJ-4k",
  authDomain: "v8te-app.firebaseapp.com",
  projectId: "v8te-app",
  storageBucket: "v8te-app.appspot.com",
  messagingSenderId: "44544826403",
  appId: "1:44544826403:web:92f6f84d9be94002b12596"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app)

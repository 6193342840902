import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VotingEvent } from '../shared/model/poll.model';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./../poll-detail/poll-detail-resolutions-list/poll-detail-resolutions-list.component.less', '../shared/common.less', './events-list.component.less']
})
export class EventsListComponent implements OnInit {
  @Input() event: VotingEvent;
  @Input() isMobile: boolean;
  @Input() votedStatuses: Array<boolean>;
  @Output() selected = new EventEmitter<number>();
  constructor() { }

  setActiveElection(event: number) {
      this.selected.emit(event);
    }

  ngOnInit(): void {
    
  }

}

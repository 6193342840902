import {Inject, Injectable} from '@angular/core';
import {forkJoin, Observable, ReplaySubject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {LazyLoadingObject} from "./lazy-loading-object";

@Injectable({
  providedIn: 'root'
})
export class LazyLoadingLibsService {

  /**
   * Objet représentant les Librairies déjà chargées.
   * Permet de ne charger qu'une seule fois un éléments.
   */
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: any) {}


  lazyLoadLibrary(elements: LazyLoadingObject): Observable<any> {

    let obsForScripts: ReplaySubject<any>  = new ReplaySubject();
    let obsForStyles: ReplaySubject<any>  = new ReplaySubject();

    this.loadScript(elements.scripts)
      .subscribe({
        next: value => console.log(value),
        complete: () => {
          // console.log('This is how it ends SCRIPTS!');
          obsForScripts.next();
          obsForScripts.complete();
        },
      });

    this.loadStyle(elements.styles)
      .subscribe({
        next: value => console.log(value),
        complete: () => {
          // console.log('This is how it ends STYLES!');
          obsForStyles.next();
          obsForStyles.complete();
        }
      });

    return forkJoin([
      obsForScripts,
      obsForStyles
    ]);
  }

  /**
   * Chargement des scripts Javascript des librairies des APIs axternes.
   * @param urls La liste des scripts à charger
   */
  private loadScript(urls: string[]): Observable<any> {

    let observableResults: Observable<any>[] = [];

    // On pourccourt les URLs à charger
    urls.forEach(function (val) {
      console.log('LIBS => Loading : ' + val);
      let url = val;

      // Checl si on a déjà chargé le script
      if (this._loadedLibraries[url]) {
        console.log('Librairie déjà chargée dans la page. On ne recharge pas une seconde fois.');
        observableResults.push(this._loadedLibraries[url].asObservable());

      } else {
        // Chargement du script
        this._loadedLibraries[url] = new ReplaySubject();

        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        script.onload = () => {
          this._loadedLibraries[url].next();
          this._loadedLibraries[url].complete();
        };

        this.document.body.appendChild(script);

        console.log('LIBS => LOADED');
        observableResults.push(this._loadedLibraries[url].asObservable());
      }
    }, this);

    return forkJoin(observableResults);
  }

  /**
   * Chargement des styles des librairies des APIs axternes.
   * @param urls La liste des style à charger
   */
  private loadStyle(urls: string[]): Observable<any> {
    let observableResults: Observable<any>[] = [];

    urls.forEach(function (val) {
      console.log('LIBS => Loading : ' + val);
      let url = val;
      // Checl si on a déjà chargé le style
      if (this._loadedLibraries[url]) {
        console.log('Style déjà chargé dans la page. On ne recharge pas une seconde fois.');
        observableResults.push(this._loadedLibraries[url].asObservable());

      } else {
        // Chargement du style
        this._loadedLibraries[url] = new ReplaySubject();

        const style = this.document.createElement('link');
        style.type = 'text/css';
        style.href = url;
        style.rel = 'stylesheet';
        style.onload = () => {
          this._loadedLibraries[url].next();
          this._loadedLibraries[url].complete();
        };

        const head = document.getElementsByTagName('head')[0];
        head.appendChild(style);

        observableResults.push(this._loadedLibraries[url].asObservable());
      }
    }, this);

    return forkJoin(observableResults);
  }
}

<div id="center-square">
  <img *ngIf="isError == false || isError == undefined" src="assets/voted-ok.svg" id="result-image">
  <img *ngIf="isError == true" src="assets/voted-ko.svg" id="result-image">
  <h1 id="header_title">
    {{title}}
  </h1>
  <h1 id="header_description">
    {{description}}
  </h1>
  <div *ngIf="scheduled == true"  id="retry" (click)="retry()">
    <img src="assets/refresh_24px.svg" id="retry-image">
    <span id="retry-text">{{'POLL-LIST.REFRESH' | translate}}</span>
  </div>
</div>

<div *ngIf="(firstname != 'New' && lastname != 'Voter') &&
            (firstname != null); else emailBlock" class="headband">
  <p class="inviteName">{{'HEADBAND.INVITATION' | translate}} {{firstname}} {{lastname}}</p>
</div>
<ng-template #emailBlock>
  <div class="headband">
    <p class="inviteName" *ngIf="!isClose">{{'HEADBAND.INVITATION' | translate}} {{email}}</p>
  </div>
</ng-template>
<!-- Utilisation du composant Template APP-VIDEO-DEFAULT -->
<div *ngIf="!isMobile">
  <app-video-default [isLive]="isLive">

    <!-- Dans lequel on inclu le composant que l'on souhaite (qui sera afficher au centre) -->
    <!-- Dans notre exemple le composant  app-included-component -->
    <app-detail-choice></app-detail-choice>

  </app-video-default>
</div>
<div *ngIf="isMobile">
  <app-detail-choice></app-detail-choice>
</div>

<div [ngClass]="isMobile == true ? 'resolutions-holder-mob' : 'resolutions-holder'">
  <h3 *ngIf="!displayElectionName" id="resolutions-header">
    {{'LISTS.TITLE' | translate}}
  </h3>
  <h3 *ngIf="displayElectionName" id="resolutions-header">
    {{electionTitle}}
  </h3>
  <app-poll-corporate-item *ngFor="let choice of choices; let i = index" [index]="i"
    [isMobile]="isMobile" [choice]="choice" (selected)=getSelection($event) [isFinal]="i == choices.length - 1" [isSelectedList]="selectedIndex === i">
  </app-poll-corporate-item>
</div>

<div class="poll-item">
  <label>
    <div id="number-holder">
      <div *ngIf="this.choice.image != undefined && this.choice.image != 'null' && this.choice.image.length > 0" class="option-image-wrapper">
        <img id="resolution-{{index}}" class="option-image" src="{{this.choice.image}}" alt="{{this.choice.text}}">
      </div>
      <div *ngIf="this.choice.image == undefined || this.choice.image == 'null' || this.choice.image.length == 0" [ngClass]="['circle' + ' circle-' + index]" id="{{'circle-' + index}}">
          <span class="resolution-index" id="{{index + 1}}">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder">
      <span *ngIf="choice.link == null || choice.link.length == 0" class="poll-item-question" (mouseenter)="hoverEvent(true)" (mouseleave)="hoverEvent(false)" id="poll-item-question-{{index}}">
        {{choice.text === '' ? '?' : choice.text}}
      </span>
      <span *ngIf="choice.link != null && choice.link.length > 0" class="poll-item-question" (mouseenter)="hoverEvent(true)" (mouseleave)="hoverEvent(false)" id="poll-item-question-{{index}}">
        <a href="{{replaceURLs(choice.link)}}" target="_blank">{{choice.text === '' ? '?' : choice.text}}</a>
      </span>
    </div>
    <div [ngClass]="['checkbox' + ' checkbox-' + index]" >
      <input class="box" type="checkbox" id="{{'checkbox-' + index}}" (click)="actionVote(index)">
      <span class="checkmark"></span>
    </div>
  </label>
</div>
<span [hidden]="!expanded" class="rollover" id="{{'rollover-' + index}}">{{choice.text === '' ? '?' : choice.text}}</span>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Round } from '../../shared/model/poll.model';


@Component({
  selector: 'app-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./../../poll-detail/poll-detail-resolutions-list/poll-resolution-item/poll-resolution-item.component.less', './event-item.component.less']
})
export class EventItemComponent implements OnInit {
  @Input() isMobile: boolean;
  @Input() index: number;
  @Input() votedData: number;
  @Input() round: Round;
  @Input() votedStatus: boolean;
  @Output() selected = new EventEmitter<number>();
  isUnanswered = true;
  imageAsset = 'assets/fleche_resolution_orange.svg';

  constructor() { }

  selectEvent() {
    if(!this.votedStatus) { this.selected.emit(this.index) };
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Result, VotingEvent, ParticipationResponse } from '../shared/model/poll.model';
import { ApiService } from '../shared/api.service';
import { Common } from '../shared/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-results',
  templateUrl: 'poll-results.component.html',
  styleUrls: ['./poll-results.component.less', '../shared/common.less', '../poll-detail/poll-detail.component.less']
})

export class PollResultsComponent implements OnInit {
  title: string;
  pollId: string;
  privateKey: string;
  isMobile: boolean;
  isResolution = false;
  question = '';
  results: Result[] = [];
  responses: number[] = [];
  votedNumber: number;
  votersNumber: number;
  participation: number;
  dateString: string;
  description: string;
  percentageString = '0';
  startDate = '';
  endDate = '';
  blockchainUrl: string;
  votingType: string;
  pollImage = '';

  constructor(private router: Router, private apiService: ApiService, private modalService: NgbModal, private common: Common) {
  }

  // getDownloadURL(ref(storage, this.choices[index][imageIndex].image)).then((url) => {
  //   this.choices[index][imageIndex].image = url
  //   this.choices = Object.assign([], this.choices);
  // })

  ngOnInit() {
    this.isMobile = this.common.checkIfMobile();
    this.pollId = this.router.url.split('/')[2];
    this.blockchainUrl = environment.settings.validation + '/' + this.pollId;
    this.apiService.getPoll(this.pollId).subscribe((res: VotingEvent) => {
      if (res.image.length > 1) { this.pollImage = res.image; }
      this.votingType = res.type;
      this.question = res.description;
      this.title = res.name;
      this.votedNumber = res.votes;
      this.votersNumber = res.voters;
      this.description = res.description;
      this.startDate = this.startDate + new Date(res.startDate).toLocaleString();
      this.participation = Math.round(this.votedNumber / this.votersNumber * 100);
      const endDateMillis = res.endDate;
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      this.dateString = new Date(endDateMillis).toLocaleDateString('fr-FR', options);
      const startDateMillis = res.startDate;
      this.startDate = new Date(startDateMillis).toLocaleDateString('fr-FR', options);
      this.apiService.getParticipation(this.pollId).subscribe((result: ParticipationResponse) => {
        if (result.votes != null ) {
          this.votedNumber = res.votes;
        }
        const percentage = (this.votedNumber / this.votersNumber) * 100;
        this.percentageString = parseFloat(percentage.toFixed(2)).toLocaleString();
      }, (err: any) => {
      });
     });
  }
}

<div [ngClass]="isMobile == true ? 'poll-item-mob' : 'poll-item'">
  <div [ngClass]="isMobile == true ? 'poll-item-description-holder-mob' : 'poll-item-description-holder'">
    <span class="poll-item-question">
      {{choice.text === '' ? '?' : choice.text}}
    </span>
  </div>
  <div class="icon-holder">
    <img src={{imageasset}} [ngClass]="isMobile == true ? 'resolution-image-mob' : 'resolution-image'">
  </div>
</div>

<div class="div-arriere-plan-action"></div>
<div *ngIf="isMobile === false" class="div-global-action">
  <div id="print-recommendations" class="content">
    <img width="70px" id="img_macaron" src="assets/warning.svg">
      <div class="recommendations-title">
        {{errorMessage}}
      </div>
      <button type="button" class="button-see-more" (click)=onDismiss()>
        OK
      </button>
  </div>
</div>
<div *ngIf="isMobile === true" class="div-global-action-mob">
  <div id="print-recommendations-mob" class="content">
    <img width="70px" id="img_macaron" src="assets/warning.svg">
      <div class="recommendations-title-mob">
          {{errorMessage}}
      </div>
      <button type="button" class="button-see-more" (click)=onDismiss()>
        OK
      </button>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {Result} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-results-rature-result',
  templateUrl: './poll-results-rature-result.component.html',
  styleUrls: ['./poll-results-rature-result.component.less']
})
export class PollResultsRatureResultComponent implements OnInit {
  @Input() result: Result;
  choice: string;

  ngOnInit() {
    this.choice = this.result.text;
  }

}

<div class="div-arriere-plan-action"></div>

<div *ngIf="isMobile === false" class="div-global-action">
  <div id="print-recommendations" class="content">
    <img width="167.5px" id="img_macaron" src="assets/gif_voted.gif">
      <div class="recommendations-title">
        {{'MODAL.DESCRIPTION' | translate}}
      </div>
  </div>
</div>
<div *ngIf="isMobile === true" class="div-global-action-mob">
  <div id="print-recommendations-mob" class="content">
    <img width="167.5px" id="img_macaron" src="assets/gif_voted.gif">
      <div class="recommendations-title-mob">
        {{'MODAL.DESCRIPTION' | translate}}
      </div>
  </div>
</div>

import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DOCUMENT} from '@angular/common';
import {LazyLoadingLibsService} from "../services/lazy-loading-libs.service";
import {Router} from "@angular/router";
import {LazyLoadingObject} from "../services/lazy-loading-object";
import {forkJoin, Observable, ReplaySubject} from "rxjs";

const LOCAL_STORAGE_KEY = 'v8teZoomKeys';

/**
 * Composant pour la connexion à l'API zoom
 * Permet d'afficher un meeting Zoom
 */
@Component({
  selector: 'app-video-zoom',
  templateUrl: './video-zoom.component.html',
  styleUrls: ['./video-zoom.component.css']
})
export class VideoZoomComponent implements OnInit, OnDestroy, AfterViewInit {

  // --------------------
  // Attributs de l'API
  /** Endpoint (serveur back) permettantd e générer la signature */
  private signatureEndpoint: string = 'https://tge-zoom-app-jwt.herokuapp.com/';
  // private signatureEndpoint: string = 'https://tge-zoom-app-sdk.herokuapp.com/';
  /** Api name de connexion à Zoom*/
  private apiKey: string = 'oXAq-37gTBqFtOpu8Ryh1g'; // JWT
  // private apiKey: string = 'EKvLVJpfAfnRc8Tftl1XPygnjFtAZJ1qWqHg'; // SDK


  /**
   * Données relatives à la conférence
   */
  private meetConfig = {
    apiKey: this.apiKey,
    userEmail: '',
    role: 0 // 1 for host; 0 for attendee
  };

  // --------------------

  /**
   * Objet représentant la librairie à charger.
   */
  private libraryToLoad: LazyLoadingObject = {
    name: 'zoom',
    scripts: [
      'https://source.zoom.us/1.8.6/lib/vendor/react.min.js',
      'https://source.zoom.us/1.8.6/lib/vendor/react-dom.min.js',
      'https://source.zoom.us/1.8.6/lib/vendor/redux.min.js',
      'https://source.zoom.us/1.8.6/lib/vendor/redux-thunk.min.js',
      'https://source.zoom.us/1.8.1/lib/vendor/jquery.min.js',
      'https://source.zoom.us/1.8.6/lib/vendor/lodash.min.js',
      'https://source.zoom.us/zoom-meeting-1.8.6.min.js'
    ],
    styles: [
      'https://source.zoom.us/1.8.6/css/bootstrap.css',
      'https://source.zoom.us/1.8.6/css/react-select.css'
    ]
  }

  private obsReady$: Observable<any>;
  private obsForLibs$: ReplaySubject<any>  = new ReplaySubject();
  private obsForPreload$: ReplaySubject<any>  = new ReplaySubject();

  @ViewChild('zoomContainer') zoomContainer: ElementRef;
  @ViewChild('errorMessage') errorContainer: ElementRef;
  readonly _currentUrlPath: string;
  private zoomMeetingElement: HTMLElement;
  public isError: boolean = false;
  public isDisplayMeeting: boolean = false;
  public isloading: boolean = false;

  public zoomMeetingInfo: ZoomMeetingInfo;

  constructor(private readonly lazyLoadingLibsService: LazyLoadingLibsService,
              public httpClient: HttpClient,
              @Inject(DOCUMENT) private document: Document,
              private router: Router,
              private renderer: Renderer2) {

    this._currentUrlPath = window.location.origin+'/'+this.router.url;
    console.log('current URL : ' + this._currentUrlPath);

    // Récupération des informations du local storage
    this.zoomMeetingInfo = this.getItemFromStorage();
  }

  ngOnInit(): void {

    this.obsReady$ = forkJoin([
      this.obsForLibs$,
      this.obsForPreload$
    ]);

    this.lazyLoadingLibsService.lazyLoadLibrary(this.libraryToLoad)
      .subscribe(_ => {
          // Chargement des librairies Zoom
          console.log('Chargement des LIB et styles OK');
        },
        error => { console.log('Erreur lors du chargement des librairies Zoom : ' + error)},
        () => {
          this.obsForLibs$.next('libs terminé')
          this.obsForLibs$.complete();
        }
      );
  }

  ngAfterViewInit() {
    console.log('Dans VideoZoomComponent.ngAfterViewInit');

    // On déplace le block généré par zoom (dans le body) dans son container cible.
    this.zoomMeetingElement = this.document.getElementById("zmmtg-root");
    console.log(this.zoomMeetingElement);

    if (!this.zoomMeetingElement) {
      // Il se peut que le ZoomElement ne soit pas dans le DOM si jamais on a fait un reset du ZoomComponent.
      // Dans ce cas il faut recreer la DIV
      this.zoomMeetingElement = this.renderer.createElement('div');
      // Set the id of the div
      this.renderer.setProperty(this.zoomMeetingElement, 'id', 'zmmtg-root');
    }
    // On met la DIV Zoom dans le bon container (par défaut, elle est ajouté directement dans le Body)
    this.zoomContainer.nativeElement.appendChild(this.zoomMeetingElement);

    this.obsForLibs$.subscribe(
      () => {},
      error => {},
      () => {
        // une fois que les Libs ont été correctement chargées, on fait le preLoad
        // Initialisation des librairies Zoom
        ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
        this.obsForPreload$.next('preload terminé')
        this.obsForPreload$.complete();
      }
    );
  }

  ngOnDestroy() {
    // On supprime le contenu du container
    // this.zoomContainer.nativeElement.innerHTML = "";
    this.isDisplayMeeting = false;
    // On essai de fermer le meeting courant
    // ZoomMtg.endMeeting({});
  }

  closeConference() {
    console.log("aici")
  }

  /**
   * Démarre une conférence Zoom
   */
  startZoomMeeting() {
    // Affichage du spinner
    this.isloading = true;
    this.isDisplayMeeting = false;

    this.putItemToStorage(this.zoomMeetingInfo);

    console.log('connexion au meeting en cours');
    console.log(this.zoomMeetingInfo);


    // On génère la signature obligatoire pour le meeting. Appel HTTP
    let signature$ = this.httpClient.post(this.signatureEndpoint, {
      meetingNumber: this.zoomMeetingInfo.meetingNumber,
      role: this.meetConfig.role
    });

    // On attends que les 2 observables soient résolus pour lancer le meeting
    // - Observable this.obsReady$ : Chargement des librairies en lazy + Preload
    // - Observable signature$ : Récupération de la signature.
    forkJoin([
      this.obsReady$,
      signature$
    ]).subscribe(
      ([data1, data2]) => {
        console.log('Tentative de démarrage du meeting');
        this.startMeeting(data2['signature']);
      },
      error => {
        console.log('Erreur lors de ForkJoin startZoomMeeting : ');
        console.log(error);
      },
      () => {
        console.log('complete fork');
      }
    );
  }

  private hideLoadingSpinner() {
    this.isloading = false;
    // On force l'affichage de l'élément Zoom
    this.zoomMeetingElement.style.display = 'block';

    (document.getElementById("iframe_Id") as HTMLIFrameElement).contentWindow.document.getElementById("button_id").addEventListener("click", this.closeConference, false);
    // this.zoomMeetingElement.style.display = 'block';
    // this.zoomMeetingElement = this.renderer.createElement('div');
    // this.renderer.setStyle(this.zoomMeetingElement, 'display', 'block !important');
  }

  startMeeting(signature) {
    console.log('--- Try to INIT ---');
    // let vid: any[] | HTMLCollectionOf<Element>
    ZoomMtg.init({
      leaveUrl: this._currentUrlPath,
      isSupportAV: true,
      success: (success) => {
        console.log('--- Try to JOIN ---');

        this.isDisplayMeeting = true;

        ZoomMtg.join({
          signature: signature,
          meetingNumber: this.zoomMeetingInfo.meetingNumber,
          userName: this.zoomMeetingInfo.userName,
          apiKey: this.meetConfig.apiKey,
          userEmail: this.meetConfig.userEmail,
          passWord: this.zoomMeetingInfo.meetingPassword,
          success: (response) => {
            console.log('--- Join OK ---');
            console.log(response);

            // this.hideLoadingSpinner();
            this.isloading = false;
            this.zoomMeetingElement.style.display = 'block';

            let vid = document.getElementsByClassName("footer-button__button");
            // console.log(vid)
            (vid[0] as HTMLElement).onclick = function() {
              let men: HTMLElement
              setTimeout(() => {
                men = document.getElementById("participantSectionMenu")
                men.click()
                setTimeout(() => {
                  let drop = document.getElementsByClassName("participants-header__menu");
                  (drop[1].firstElementChild as HTMLElement).click()
                }, 10)
              }, 100)
            };
            let chatIndex = this.checkIfSafari() ? 1 : 2;
            (vid[chatIndex] as HTMLElement).onclick = function() {
              let men: HTMLElement
              setTimeout(() => {
                men = document.getElementById("chatSectionMenu")
                men.click()
                setTimeout(() => {
                  let drop = document.getElementsByClassName("chat-header__menu");
                  (drop[1].firstElementChild as HTMLElement).click()
                }, 10)
              }, 100)
            }

            let leave = document.getElementsByClassName("footer__leave-btn")
            let close = document.getElementsByClassName("return-text");
            (leave[0] as HTMLElement).onclick = function() {
              (close[0] as HTMLElement).click()
            }

          },
          error: (error) => {
            console.log('Erreur lors de la tentative pour rejoindre le meeting : ');
            console.log(error);
            this.hideLoadingSpinner();
          }
        })
      },
      error: (error) => {
        console.log('Erreur lors de l\'initialisation du meeting : ');
        console.log(error);
        this.hideLoadingSpinner();
      },
    })
  }

  checkIfSafari() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return false;
      } else {
        if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                   navigator.userAgent &&
                   navigator.userAgent.indexOf('CriOS') == -1 &&
                   navigator.userAgent.indexOf('FxiOS') == -1)
          {
            return true
          }
        return false
      }
    }

  /**
   * Stocke les inforamtions de la conférence dans le storage.
   * @param item
   * @private
   */
  private putItemToStorage(item: ZoomMeetingInfo) {
    // Si le storage est disponible, alors on met les information sur la conférence.
    if (window.sessionStorage) {
      sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(item));
    }
  }

  /**
   * Récupération des information du Storage si il est accessible ET si il contient des informations.
   * @private
   */
  private getItemFromStorage(): ZoomMeetingInfo {
    // SI on peut lire le storage du browser et si on a des informations.
    if (window.sessionStorage) {
      let storeItem = sessionStorage.getItem(LOCAL_STORAGE_KEY);
      if (storeItem) {
        return JSON.parse(storeItem);
      }
    }
    // Sinon on renvoit un objet vide
    return {
      meetingNumber: null,
      meetingPassword : null,
      userName : null
    };
  }
}

export interface ZoomMeetingInfo {
  meetingNumber: number;
  meetingPassword: string;
  userName: string;
}

export declare class ZoomMtg {
  static setZoomJSLib(params1: any, params2: any): any;
  static init(params: any): any;
  static join(params: any): any;
  static endMeeting(params: any): any;
  static preLoadWasm();
  static prepareJssdk();
}

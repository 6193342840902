import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Choice} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-corporate-list',
  templateUrl: './poll-corporate-list.component.html',
  styleUrls: ['./../poll-detail-list/poll-detail-list.component.less', '../../shared/common.less'],
})

export class PollCorporateListComponent {
  @Input() electionTitle: string;
  @Input() isMobile: boolean;
  @Input() choices: Choice[];
  @Input() displayElectionName: boolean = false
  @Output() sendListSelection = new EventEmitter<number>();
  @Input() selectedIndex: number;

  getSelection($event: number) {
    this.sendListSelection.emit($event);
  }

  ngOnInit() {
    // console.log(this.choices);
    
  }
}

<div *ngIf="isMobile === false" class="poll-item">
  <label>
    <div id="number-holder">
      <div *ngIf="choice.listImages.length > 0 && choice.listImages[0] != 'null'">
        <img id="proposition-{{index}}" class="option-image" src="{{choice.listImages[0]}}" alt="{{choice.question}}">
      </div>
      <div *ngIf="choice.listImages.length == 0 || choice.listImages[0] == 'null'" [ngClass]="answer === 0 ? 'circle-unanswered' : 'circle'">
        <span class="resolution-index">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder">
      <span *ngIf="choice.links[0] == null" [ngClass]="answer === 1 ? 'poll-item-question' : 'poll-item-question-unanswered'">
        {{choice.question === '' ? '?' : choice.question}}
      </span>
      <span *ngIf="choice.links[0] != null" [ngClass]="answer === 1 ? 'poll-item-question' : 'poll-item-question-unanswered'">
        <a href="{{replaceURLs(choice.links[0])}}" target="_blank">{{choice.question === '' ? '?' : choice.question}}</a>
      </span>
    </div>
    <div [ngClass]="['checkbox' + ' checkbox-' + index]" >
      <input class="box" type="checkbox" id="{{'checkbox-' + index}}" (click)=actionVote()>
      <span class="checkmark"></span>
    </div>
  </label>
</div>
<div *ngIf="isMobile === true" class="poll-item">
  <label>
    <div id="number-holder">
      <div *ngIf="choice.listImages.length > 0 && choice.listImages[0] != 'null'">
        <img id="proposition-{{index}}" class="option-image" src="{{choice.listImages[0]}}" alt="{{choice.question}}">
      </div>
      <div *ngIf="choice.listImages.length == 0 || choice.listImages[0] == 'null'" [ngClass]="answer === 0 ? 'circle-unanswered' : 'circle'">
        <span class="resolution-index">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder-mob">
      <span *ngIf="choice.links[0] == null" [ngClass]="answer === 1 ? 'poll-item-question' : 'poll-item-question-unanswered'">
        {{choice.question === '' ? '?' : choice.question}}
      </span>
      <span *ngIf="choice.links[0] != null" [ngClass]="answer === 1 ? 'poll-item-question' : 'poll-item-question-unanswered'">
        <a href="{{replaceURLs(choice.links[0])}}" target="_blank">{{choice.question === '' ? '?' : choice.question}}</a>
      </span>
    </div>
    <div [ngClass]="['checkbox' + ' checkbox-' + index]" >
      <input class="box" type="checkbox" id="{{'checkbox-' + index}}" (click)=actionVote()>
      <span class="checkmark"></span>
    </div>
  </label>
</div>

import {Component, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {ReceivedQuestion} from '../../../shared/model/poll.model';

@Component({
  selector: 'app-poll-corporate-details-item',
  templateUrl: './poll-corporate-details-item.component.html',
  styleUrls: ['./../../poll-detail-resolutions-list/poll-resolution-item/poll-resolution-item.component.less', './poll-corporate-details-item.component.less'],
})
export class PollCorporateDetailsItemComponent implements AfterViewInit {

  @Input() choice: ReceivedQuestion;
  @Input() isMobile: boolean;
  @Input() index: number;
  @Input() answer: number;
  @Input() votedData: number[];
  @Output() selected = new EventEmitter<number>();


  constructor() {
  }

  ngAfterViewInit() {
    if (this.answer == 1) {
      const checkbox = document.getElementById("checkbox-" + this.index) as HTMLInputElement
      checkbox.checked = true      
    }
  }

  actionVote() {
    this.selected.emit(this.index);
  }

  replaceURLs(message: string) {    
    if (!message) { return; }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
        let hyperlink = url;
        if (!hyperlink.match('^https?:\/\/')) {
          hyperlink = 'http://' + hyperlink;
        }
        return hyperlink
      });
  }
}

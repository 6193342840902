<div class="container-fluid video-main-content">
  <!-- <div class="row justify-content-end"> -->
    <div class="logo-container" *ngIf="!isDisplayZoomApp && !isDisplayJitsiApp">
      <a [href]="'//' + v8te" id="v8te-url-image"><img id="v8te-logo" src="assets/v8te.png" alt="v8te-loge"></a>
      <!-- <ng-content></ng-content> -->
    </div>
    <!--  Section dans laquelle on va ajouter le contenu provenant du Parent -->
    <div class="video-included-content">
      <ng-content></ng-content>
    </div>

    <!--  Section Pour les liens vers les videos conf -->
    <div class="video-link-content align-self-start" *ngIf="!isDisplayZoomApp && !isDisplayJitsiApp">
      <div id="video-calls-container" *ngIf="isLive == true">
        <div class="display-visio-container" (click)="displayVisio()" style="cursor: pointer;">
          <h3 class="video-link-content-title">{{'POLL-DETAIL.VIDEO' | translate}}</h3>
          <img src="assets/camera_icon.svg" alt="camera-icon" id="cameraButton">
        </div>

        <ul class="links" id="visioServices">
          <li class="line">
            <a (click)="displayZoomVideo()">
              <img src="assets/zoom-logo.png" class="meeting-icon" alt="Zoom videoconférence">
            </a>
          </li>
          <li>
            <a (click)="displayJitsiVideo()">
              <img src="assets/logo-jitsi-meet.png" class="meeting-icon" alt="Jitsi Meet videoconférence">
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- --- -->
    <!--  Section Affichage de la video Conf sélectionnée -->
    <div class="video-container-test" *ngIf="isDisplayZoomApp || isDisplayJitsiApp">
      <div class="video-conf-content">
        <div class="return-text" (click)="destroyChild()">{{'POLL-VIDEO.RELOAD' | translate}}</div>
        <app-video-zoom *ngIf="isDisplayZoomApp"></app-video-zoom>
        <app-video-jitsi *ngIf="isDisplayJitsiApp"></app-video-jitsi>
      </div>
    </div>
    
  <!-- </div> -->
</div>

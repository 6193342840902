<div class="div-arriere-plan-action" (click)="clicAction($event)"></div>
<div class="div-global-action" (click)="clicAction($event)">
  <div id="center-square">
    <!-- <div id="blue-lines"></div> -->
    <div class="text-holder">
      <h1 class="header_title title">
        {{title}}
      </h1>
      <h1 *ngIf="firstText !== undefined" class="header_title">
        {{firstText}}
      </h1>
      <h1 *ngIf="secondText !== undefined" class="header_title">
        {{secondText}}
      </h1>
      <h1 *ngIf="thirdText !== undefined" class="header_title">
        {{thirdText}}
      </h1>
      <div class="separator"></div>
      <input *ngIf="phoneInput" type="tel" id="phone-input" placeholder="Mon numéro de téléphone"
        (change)="onPhoneEdit($event)">
    </div>

    <div class="offers-holder" *ngIf="index == 0">
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.HUSSAR-SINGULAR' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.FORMATION-2H' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.FULL-SERVICE' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">1 068€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">890€ HT</span>
      </div>
    </div>
    <div class="offers-holder" *ngIf="index == 1">
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.PACK-500-SMS' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">180€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">400€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.PACK-1000-SMS' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">240€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">600€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.PACK-5000-SMS' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">960€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">2 400€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.HUSSAR-SINGULAR' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.FORMATION-2H' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">588€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.FULL-SERVICE' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">1 068€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">890€ HT</span>
      </div>
    </div>
    <div class="offers-holder" *ngIf="index >= 2">
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.PACK-500-SMS' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">180€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">400€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.PACK-1000-SMS' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">240€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">600€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.PACK-5000-SMS' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">960€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">2 400€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.PACK-10000-SMS' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">1 800€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">4 500€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.HUSSAR-PLURAL' | translate}}</span>
        <span class="offer-price">{{'PRICE-DETAILS.QUOTE' | translate}}</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.FORMATION-PL' | translate}}</span>
        <span *ngIf="isAssoc" class="offer-price">1 068€ TTC</span>
        <span *ngIf="!isAssoc" class="offer-price">490€ HT</span>
      </div>
      <div class="offer-line">
        <span class="offer-text">{{'PRICE-DETAILS.FULL-SERVICE' | translate}}</span>
        <span class="offer-price">{{'PRICE-DETAILS.QUOTE' | translate}}</span>
      </div>
    </div>

    <!-- (change)="onPhoneEdit($event)" -->

    <div *ngIf="phoneInput" class="button-holder">
      <button id="button-cancel" class="phone-cancel" (click)="cancelDetails(false)">{{'PRICE-DETAILS.CANCEL' |
        translate}}</button>
      <button *ngIf="isPhoneValid" id="button-vote" class="button-blue" (click)="sendContactPhone()">{{'PRICE-DETAILS.RECALL' |
        translate}}</button>
      <button *ngIf="!isPhoneValid" id="button-vote-invalid">{{'PRICE-DETAILS.RECALL' | translate}}</button>
    </div>

    <div *ngIf="!phoneInput" class="button-holder-popup">
      <button *ngIf="cancelButton" type="button" id="button-cancel" (click)="cancelDetails(false)">
        {{'OFFERS.WARNING.CANCEL' | translate}}
      </button>
      <button *ngIf="modifyButton" type="button" id="button-cancel" (click)="cancelDetails(false)">
        {{'POLL-WARNING.CANCEL' | translate}}
      </button>
      <button *ngIf="validateButton" type="button" id="button-vote" class="button-blue" (click)="launch()">
        {{'OFFERS.WARNING.CONFIRM' | translate}}
      </button>
      <button *ngIf="understoodButton" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'DETAILS-CONTACT.UNDERSTOOD' | translate}}
      </button>
      <button *ngIf="callButton" type="button" id="button-vote" class="button-blue" (click)="cancelDetails(true)">
        {{'PRICE-DETAILS.RECALL' | translate}}
      </button>
      <button *ngIf="confirmButton" type="button" id="button-vote" class="button-blue" (click)="cancelDetails(true)">
        {{'POLL-WARNING.CLOSE' | translate}}
      </button>
      <div *ngIf="votingAvailableButtons" style="display: flex; flex-direction: column">
        <button type="button" id="button-vote" class="button-blue" (click)="cancelDetails(true)">
          {{'POLL-WARNING.CLOSE-AVAILABLE' | translate}}
        </button>
        <button type="button" id="button-cancel" (click)="cancelDetails(false)">
          {{'POLL-WARNING.CANCEL-AVAILABLE' | translate}}
        </button>
      </div>
    </div>

    <div *ngIf="isValidated == false" class="page-container centering">
      <!-- CHANGER LE TEXTE EN DUR -->
      <form action="post">
        <div class="input-container" >
          <!-- (keypress)="codePressed($event)" -->
          <input id="letter-1" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" autocomplete="one-time-code" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-2" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-3" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-4" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-5" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-6" (keydown)="codeInput($event)" (change)="validate()" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" tabindex="1">
        </div>

        <!-- CHANGER "Validate" -->
        <!-- <input type="button" id="button-vote-second" class="button" value="{{'2FA.VALIDATE' | translate}}" (click)="validate()"> -->
      </form>
      <div *ngIf="(isValidationCodeError && shouldCalculateError) || noMoreSMS" class="dfa-error-message">
        <p *ngIf="isValidationCodeError && shouldCalculateError" style="color: red; margin-bottom: 0px;">{{'2FA.ERROR' | translate}}</p>
        <p *ngIf="noMoreSMS" style="color: red;">{{'2FA.NO-MORE-SMS' | translate}}</p>
      </div>
      <p *ngIf="retries >= 0" class="resend-title">{{'2FA.RESEND-TITLE' | translate}}</p>
      <p *ngIf="retries != 1 && retries >= 0" class="resend" (click)="resendSMS()">{{'2FA.RESEND' | translate}}{{phoneNumber}} ({{retries}} {{'2FA.REMAINING' | translate}}</p>
      <p *ngIf="retries == 1" class="resend" (click)="resendSMS()">{{'2FA.RESEND' | translate}}{{phoneNumber}} ({{retries}} {{'2FA.ONE-REMAINING' | translate}}</p>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row jitsi">

    <!-- Spinner de chargement -->
    <div class="v8te-spinner  align-self-start" [hidden]="!isLoading">
      <div class="v8te-spinner-dot"></div>
      <div class="v8te-spinner-dot"></div>
      <div class="v8te-spinner-dot"></div>
      <div class="v8te-spinner-dot"></div>
      <div class="v8te-spinner-dot"></div>
      <div class="v8te-spinner-dot"></div>
    </div>

    <!-- Conteneur pour la vidéo -->
    <div #jitsiContainer id="jitsiContainer" class="col-12">

    </div>
  </div>
</div>

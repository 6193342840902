import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Ballot } from './model/poll.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiURL: string;
  resultsURL: string;
  httpOptions = {
    headers: new HttpHeaders({
      // tslint:disable-next-line:max-line-length
      Authorization:
        'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJhdWQiOiJ2OHRlLWFwaSIsInN1YiI6Ijg5MmVjZmY5LTI0NWUtNDQzMy05Nzc5LTFkMTk5OTg5ZDE4MCIsImlzcyI6Imh0dHBzOi8vYXV0aC52OHRlLmNvbS8iLCJleHAiOjE1ODIyMDUwMjAsImlhdCI6MTU4MjIwMTQyMH0.bOybPjpzU1EUilWCsIkkO5Z4GjvL3jw9I6YScr6paZIcXUZcv8U7USHVwnLrh3KNt8Nyog-MTOFMFmY2tNpwZA',
        'Content-Type': 'application/json; charset=UTF-8',
    }),
  };

  constructor(private httpClient: HttpClient) {}

  public getPoll(id: string) {
    return this.httpClient.get(
      `${environment.settings.backend}/elections/${id}/public`
    );
  }

  public createBallot(id: string, ballot: Ballot, validation: string, round: number) {
    return this.httpClient.post(
      `${environment.settings.backend}/elections/${id}/vote`,
        { ballotRaw: ballot, validation: validation, round: round }
    );
  }

  public sendVoteConfirmation(id: string, hash: string, priv: string, round: number) {
    return this.httpClient.post(
      `${environment.settings.backend}/elections/${id}/voteHash`,
        {hash: hash, key: priv, round: round}
    )
  }

  public validateVoter(id: string, pubkey: string, confirmationCode: string) {
    return this.httpClient.post(
      `${environment.settings.backend}/elections/${id}/validateCode`,
      { pubkey: pubkey, confirmationCode: confirmationCode }
    );
  }

  public get2FAStatus(id: string, pubkey: string) {
    return this.httpClient.post(
      `${environment.settings.backend}/elections/${id}/validationStatus`,
      { pubkey: pubkey }
    );
  }

  public getSMS(id:string, pubkey: string) {
    return this.httpClient.post(
      `${environment.settings.backend}/elections/${id}/resendConfirmationCode`,
      { pubkey: pubkey }
    );
  }

  public getResults(id: string) {
    return this.httpClient.get(
      `${environment.settings.backend}/elections/${id}/result`
    );
  }

  public getParticipation(id: string) {
    return this.httpClient.get(
      `${environment.settings.backend}/elections/${id}/participation`,
      this.httpOptions
    );
  }

  public getParticipationRound(id: string, round: number) {
    return this.httpClient.get(
      `${environment.settings.backend}/elections/${id}/participation?round=${round}`,
      this.httpOptions
    );
  }

  public getVotedStatus(id: string, key: string) {
    return this.httpClient.get(
      `${environment.settings.backend}/elections/${id}/hasVoted?key=${key}`,
      this.httpOptions
    );
  }

  public recoverEmail(mail: string) {
    const fd = new FormData();
    fd.append('email', mail);
    return this.httpClient.post<any>(
      `${environment.settings.backend}/voter/lostinvitation`,
      fd
    );
  }

  public getVoterInfo(id: string, pubkey: string) {
    return this.httpClient.post<any>(
      `${environment.settings.backend}/voter/${id}`,
      { pubkey: pubkey }
    );
  }

  public getVoterName(id: string, pubkey: string) {
    return this.httpClient.post<any>(
      `${environment.settings.backend}/voter/poll-voter/${id}`,
      { pubkey: pubkey }
    );
  }

  public getPollPreview(id: string) {
    return this.httpClient.get(
      `${environment.settings.backend}/elections/${id}/preview`,
      this.httpOptions
    );
  }

  public getAssessorUrl(code: string) {
    return this.httpClient.get(
      `${environment.settings.backend}/assessors/invite/${code}`,
      this.httpOptions
    );
  }

  public postAssessorValidation(code: string, invitation: string) {
    return this.httpClient.post(
      `${environment.settings.backend}/assessors/invite/${invitation}`,
      { code: code }
    );
  }

  public postAssessorTally(code: string, electionId: string) {
    return this.httpClient.post(
      `${environment.settings.backend}/assessors/tally/${electionId}`,
      { code: code }
    );
  }

  public getAssesssorInfo(code: string) {
    return this.httpClient.get(
      `${environment.settings.backend}/assessors/invite/info/${code}`,
      this.httpOptions
    );
  }
}

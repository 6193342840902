import { Injectable } from "@angular/core";


@Injectable({
    providedIn: "root",
})

export class Common {

    localizeAnswer(answer: string): string {
      const userLang = navigator.language || navigator['userLanguage'];
      if (answer === 'Vote blanc') {
        switch (userLang.substring(0,2)) {
            case 'es': return 'Voto en blanco';
            case 'en': return 'Blank vote';
        }
      }
      return answer;
    }

  replaceURLs(message: string) {
    if (!message) { return; }
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, (url) => {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
    });
  }
  checkIfMobile() {
    return !!(navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i));
  }
}

import {Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Choice} from '../shared/model/poll.model';

@Component({
  selector: 'app-poll-choice',
  templateUrl: 'poll-choice.component.html',
  styleUrls: ['./poll-choice.component.less']
})

export class PollChoiceComponent implements OnInit {
  @Input() choice: Choice;
  @Input() isMobile: boolean;
  @Input() index: number;
  @Input() question: string;
  @Output() selected = new EventEmitter<string>();

  actionVote() {
    this.selected.emit('selected');
  }

  ngOnInit() {
  }
}

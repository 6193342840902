import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-poll-vote-footer',
  templateUrl: './poll-vote-footer.component.html',
  styleUrls: ['./poll-vote-footer.component.less', '../../shared/common.less'],
})

export class PollVoteFooterComponent {
  @Input() status: string;
  @Input() validButton: boolean = true;
  @Input() twoButtons: Boolean;
  @Output() selected = new EventEmitter<string>();

  actionVoted() {
    this.selected.emit('voted');
  }

  actionCancel() {
    this.selected.emit('cancel');
  }
}

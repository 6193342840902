<div class="poll-item" (click)=actionVote()>
  <label>
    <div id="number-holder">
      <div *ngIf="isUnanswered" class = "circle-unanswered">
        <span class="resolution-index">{{index+1}}</span>
      </div>
      <img *ngIf="!isUnanswered" class="voted-checkbox" src="assets/voted-check-circle.svg">
    </div>
    <div [class]="isMobile? 'poll-item-description-holder-mob' : 'poll-item-description-holder'">
      <a *ngIf="election.links[0] != null else noLink" href="{{replaceURLs(election.links[0])}}" target="_blank" style="width: fit-content;">
        {{election.question === '' ? '?' : election.question}}
      </a>
      <ng-template #noLink>
        <span class="poll-item-question">
          {{election.question === '' ? '?' : election.question}}
        </span>
      </ng-template>
    </div>
    <img src={{imageAsset}} class="helper-image">
  </label>
</div>

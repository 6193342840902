<div class="headband" style="opacity: 80%;">
  <p *ngIf="!isPollDeleted && !closedPoll" class="inviteName">{{'HEADBAND.INVITATION' | translate}} {{email}}</p>
</div>
<div class="logo-container">
  <a *ngIf="!isMobile" href='https://www.v8te.com/'><img [ngClass]="isMobile ? 'v8te-logo v8te-logo-mobile' : 'v8te-logo'"
     src="assets/v8te.png" alt="v8te-loge"></a>
</div>
<div *ngIf="!isPollDeleted && !closedPoll" class="summary">
  <h3 style="margin-top: 10px" class="title">
    {{electionDataJson != null ? electionDataJson.name : ""}}
  </h3>
  <div *ngIf="electionDataJson != null" class="secret-container">
    <img src="assets/info_icon.svg" alt="alert" class="icon">
    <div *ngIf="electionDataJson.isPublicVote != null">
      <p *ngIf="electionDataJson.isPublicVote" class="is-secret">{{'POLL-DETAIL.PUBLIC' | translate}}</p>
      <p *ngIf="!electionDataJson.isPublicVote" class="is-secret">{{'POLL-DETAIL.SECRET' | translate}}</p>
    </div>
  </div>

  <div id="firstLine" *ngIf="electionDataJson != null && electionDataJson.description != ' '">
    <p class="description-long" [innerHTML] = "electionDataJson != null ? common.replaceURLs(electionDataJson.description) : ''"></p>
    <div *ngIf="electionDataJson != null && electionDataJson.sharedLinks != null">
      <p class="description-black">
        <span id="document-span">{{'POLL-DETAIL.LINK' | translate}}</span><a [innerHTML]="common.replaceURLs(electionDataJson.sharedLinks)" style="display: block; overflow: hidden; text-overflow: ellipsis;">{{electionDataJson.sharedLinks}}</a>
      </p>
    </div>
  </div>
  <div class="infos-holder" *ngIf="status >= 3">
    <div class="holder-voters">
      <circle-progress id="attendance" [percent]="voterParticipation" [radius]="21" [outerStrokeWidth]="4"
        [innerStrokeWidth]="4" [space]="-4" [outerStrokeColor]="'#1890FF'" [innerStrokeColor]="'#e7e8ea'"
        [titleFontSize]="14" [titleColor]="'#1890FF'" [unitsColor]="'#1890FF'" [unitsFontSize]="14"
        [showSubtitle]="false" [animation]="true" [animationDuration]="300" [startFromZero]="false">
      </circle-progress>
      <div class="descriptions-holder">
        <h3 class="info-black">{{electionDataJson != null ? electionDataJson.votes : ""}}</h3>
        <h3 class="info-grey">sur {{electionDataJson != null ? electionDataJson.voters: ""}}</h3>
      </div>
    </div>
    <div class="holder-voters" id="left-holder">
      <img class="calendar-image" src="assets/calendar.svg">
      <div class="descriptions-holder">
        <h3 class="info-grey">{{'POLL-DETAIL.CLOSING' | translate}}</h3>
        <h3 class="info-black">{{dateString}}</h3>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <div *ngIf="status == 1" style="margin-top: 40px;">
    <p *ngIf="electionDataJson != null && electionDataJson.assessors != null" class="resolutions-participation">
      <span class="title" style="margin-right: 10px;">{{'POLL-VALIDATION.ASSESORS' | translate}}</span>
      <span class="participation-label">{{electionDataJson.validatedAssessors}}/{{electionDataJson.assessors}}</span>
      <progress max="100" value={{participation}}> 70% </progress>
    </p>
    <p class="description-validation">{{'POLL-VALIDATION.SCAN' | translate}}</p>
    <qrcode [qrdata]="urlCode" [width]="256" [errorCorrectionLevel]="'M'" style="text-align: center;"></qrcode>
    <p style="text-align: center;">{{'POLL-VALIDATION.OR' | translate}}</p>
    <p class="description-validation" style="margin-bottom: 30px;">{{'POLL-VALIDATION.MANUAL-1' | translate}}<span style="font-weight: bold;">V8TE</span>{{'POLL-VALIDATION.MANUAL-2' | translate}}<span style="font-weight: bold;">{{inviteCode}}</span>{{'POLL-VALIDATION.MANUAL-3' | translate}}</p>
    <p class="description-validation">{{'POLL-VALIDATION.PASSWORD' | translate}}</p>
    <div class="page-container centering">
      <form action="post">
        <div class="number-container" >
          <input id="letter-1" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" autocomplete="one-time-code" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-2" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-3" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-4" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-5" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-6" (keydown)="codeInput($event)" (change)="validate()" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" tabindex="1">
        </div>
      </form>
      <div *ngIf="shouldDisplayCodeError" class="dfa-error-message">
        <p style="color: red; margin-bottom: 0px; text-align: center;">{{'2FA.ERROR' | translate}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="status == 2" style="margin-top: 40px;">
    <app-vote-submit-confirmation *ngIf="electionDataJson.validatedAssessors == electionDataJson.assessors" title="{{'POLL-VALIDATION.BRAVO' | translate}}"
    description="{{'POLL-VALIDATION.SYNC-OK' | translate}}"
   [isError]="false">
    </app-vote-submit-confirmation>
    <app-vote-submit-confirmation *ngIf="electionDataJson.validatedAssessors != electionDataJson.assessors" title="{{'POLL-VALIDATION.VALIDATED' | translate}}"
    description="{{'POLL-VALIDATION.SYNC-PARTIAL' | translate}}"
   [isError]="false">
    </app-vote-submit-confirmation>
    <div class="notices">
      <h3 class="notice-title">{{'POLL-WARNING.CATCH' | translate}}</h3>
      <h3 class="notice-description">{{'POLL-VALIDATION.REMINDER' | translate}}</h3>
    </div>
  </div>
  <div *ngIf="status == 3" style="margin-top: 20px;">
    <div class="notices">
      <h3 class="notice-title">{{'POLL-WARNING.CATCH' | translate}}</h3>
      <h3 class="notice-description">{{'POLL-VALIDATION.ONGOING' | translate}}</h3>
    </div>
  </div>
  <div *ngIf="status == 4" style="margin-top: 40px;">
    <p *ngIf="electionDataJson != null && electionDataJson.assessors != null" class="resolutions-participation">
      <span class="title" style="margin-right: 10px;">{{'POLL-VALIDATION.ASSESORS' | translate}}</span>
      <span class="participation-label">{{electionDataJson.validatedAssessors}}/{{electionDataJson.assessors}}</span>
      <progress max="100" value={{participation}}> 70% </progress>
    </p>
    <p class="description-validation">{{'POLL-VALIDATION.CLOSING' | translate}}</p>
    <div class="page-container centering">
      <form action="post">
        <div class="number-container" >
          <input id="letter-1" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" autocomplete="one-time-code" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-2" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-3" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-4" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-5" (keydown)="codeInput($event)" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" moveNextByMaxLength tabindex="1">
          <input id="letter-6" (keydown)="codeInput($event)" (change)="validate()" type="text" class="number-input" maxlength="1" inputmode="numeric" pattern="[0-9]*" tabindex="1">
        </div>
      </form>
      <div *ngIf="shouldDisplayCodeError" class="dfa-error-message">
        <p style="color: red; margin-bottom: 0px; text-align: center;">{{'2FA.ERROR' | translate}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="status == 5" style="margin-top: 40px;">
    <app-vote-submit-confirmation *ngIf="electionDataJson.validatedAssessors < 2" title="{{'POLL-VALIDATION.VALIDATED' | translate}}"
      description="{{'POLL-VALIDATION.VALIDATED-DESCRIPTION' | translate}}"
      [isError]="false">
    </app-vote-submit-confirmation>
    <app-vote-submit-confirmation *ngIf="electionDataJson.validatedAssessors >= 2" title="{{'POLL-VALIDATION.BRAVO' | translate}}"
      description="{{'POLL-VALIDATION.VALIDATED-OK' | translate}}"
      [isError]="false">
    </app-vote-submit-confirmation>
  </div>
  <div *ngIf="status > 1" style="text-align: center; margin-top: 20px">
    <button class="button-blue" (click)="openPreview()">{{'POLL-VALIDATION.REJOIN-ELECTION' | translate}}</button>
  </div>
</div>
<app-assessors-popup *ngIf="isPollDeleted" title="{{'POLL-DELETED.TITLE' | translate}}"
  description="{{'POLL-DELETED.DESCRIPTION' | translate}}">
</app-assessors-popup>
<app-assessors-popup *ngIf="closedPoll" title="{{'POLL-COMPLETED.TITLE' | translate}}"
  description="{{'POLL-COMPLETED.DESCRIPTION' | translate}}">
</app-assessors-popup>

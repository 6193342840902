import {Component, Input, OnInit} from '@angular/core';
import {Result} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-results-result',
  templateUrl: './poll-results-result.component.html',
  styleUrls: ['./poll-results-result.component.less'],
})
export class PollResultsResultComponent implements OnInit {
  @Input() result: Result;
  choice: string;

  ngOnInit() {
    const languageKey = 'userLanguage';
    const userLang = navigator.language || navigator[languageKey];
    switch (this.result.text) {
      case 'POUR': {
        this.choice = userLang.substring(0, 2) === 'fr' ? 'POUR' : 'FOR';
        break;
      }
      case 'CONTRE': {
        this.choice = userLang.substring(0, 2) === 'fr' ? 'CONTRE' : 'AGAINST';
        break;
      }
      case 'ABSTENTION': {
        this.choice = 'ABSTENTION';
        break;
      }
      default:
        this.choice = this.result.text;
        break;
    }
  }

}

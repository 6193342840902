import {Component, Input} from '@angular/core';
import {ResolutionResult} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-results-resolution-list',
  templateUrl: './poll-results-resolution-list.component.html',
  styleUrls: ['./poll-results-resolution-list.component.less'],
  styles: [`
    ngb-progressbar {
      margin-top: 5rem;
    }
  `]
})
export class PollResultsResolutionListComponent {
  @Input() result: ResolutionResult;
  @Input() index: number;
}

<div *ngIf="isMobile === false">
  <div id="center-square">
    <h1 id="header_description">
      {{'POLL-WARNING.SUBTITLE' | translate}}
    </h1>
    <h1 id="header_title">
      {{'POLL-WARNING.TITLE' | translate}}
    </h1>
    <div class="button-holder">
     <button type="button" id="button-cancel" (click)="cancel()">
      {{'POLL-CHOICE.ACTION_CANCEL' | translate}}
     </button>
     <button type="button" id="button-vote" class="button-blue" (click)="vote()">
       {{'POLL-CHOICE.FOOTER_CONFIRM' | translate}}
     </button>
    </div>
  </div>
</div>
<div *ngIf="isMobile === true">
  <div id="center-square-mob">
    <h1 id="header_description">
      {{'POLL-WARNING.SUBTITLE' | translate}}
    </h1>
    <h1 id="header_title">
      {{'POLL-WARNING.TITLE' | translate}}
    </h1>
    <div class="button-holder">
     <button type="button" id="button-cancel" (click)="cancel()">
      {{'POLL-CHOICE.ACTION_CANCEL' | translate}}
     </button>
     <button type="button" id="button-vote" class="button-blue" (click)="vote()">
       {{'POLL-CHOICE.FOOTER_CONFIRM' | translate}}
     </button>
    </div>
  </div>
</div>

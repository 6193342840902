import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-vote-submit-confirmation',
  templateUrl: 'vote-submit-confirmation.component.html',
  styleUrls: ['./vote-submit-confirmation.component.less']
})

export class VoteSubmitComponent {
  @Input() isMobile: boolean;
  @Input() isError: boolean;
  @Input() title: string;
  @Input() description: string;
  @Input() scheduled: boolean;
  @Output() shouldRetry = new EventEmitter<boolean>();

  retry() {
    this.shouldRetry.emit(true);
  }

  constructor() {
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DynamicDisplayComponent} from './dynamic-display/dynamic-display.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angularx-qrcode';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {V8teConfModule} from './v8te-conf/v8te-conf.module';
import {PollChoiceComponent} from './poll-choice/poll-choice.component';
import {PollDetailComponent} from './poll-detail/poll-detail.component';
import {PollVoteComponent} from './poll-vote/poll-vote.component';
import { PollDetailChoicePlurinominalComponent } from './poll-detail/poll-detail-choice-plurinominal/poll-detail-choice-plurinominal.component';
import {PollResultsComponent} from './poll-results/poll-results.component';
import {PollResultsListComponent} from './poll-results/poll-results-list/poll-results-list.component';
import {PollResultsResolutionListComponent} from './poll-results/poll-results-resolution-list/poll-results-resolution-list.component';
import {PollResultsResultComponent} from './poll-results/poll-results-result/poll-results-result.component';
import {PollDetailListComponent} from './poll-detail/poll-detail-list/poll-detail-list.component';
import {PollDetailResolutionsListComponent} from './poll-detail/poll-detail-resolutions-list/poll-detail-resolutions-list.component';
import {PollChoiceFooterComponent} from './poll-choice/poll-choice-footer/poll-choice-footer.component';
import {PollVoteFooterComponent} from './poll-vote/poll-vote-footer/poll-vote-footer.component';
import {PollVoteResolutionFooterComponent} from './poll-vote/poll-vote-resolution-footer/poll-vote-resolution-footer.component';
import {PollResolutionVoteComponent} from './poll-detail/poll-resolution-vote/poll-resolution-vote.component';
import {PollVoteWarningComponent} from './poll-detail/poll-vote-warning/poll-vote-warning.component';
import {RecommendationsComponent} from './poll-detail/poll-modal/recommendations.component';
import {ErrorComponent} from './poll-detail/poll-error/error.component';
import {VoteSubmitComponent} from './poll-detail/vote-submit-confirmation/vote-submit-confirmation.component';
import {PollResolutionItemComponent} from './poll-detail/poll-detail-resolutions-list/poll-resolution-item/poll-resolution-item.component';
import {PollResolutionOptionComponent} from './poll-detail/poll-detail-resolutions-list/poll-resolution-option/poll-resolution-option.component';
import {PollResolutionChoicesListComponent} from './poll-detail/poll-detail-resolutions-list/poll-resolution-choices-list/poll-resolution-choices-list.component';
import {WarningPopupComponent} from './warning-popup/warning-popup.component';
import {AssessorsPopupComponent} from './assessors-popup/assessors-popup.component';
import {RecoveryComponent} from './key-recover/recovery.component';
import {I18nService} from './core/i18n.service';
import {AppConfigService} from './shared/app-config.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {PollResultsHolderComponent} from './poll-results/result-holder/result-holder.component';
import {PollElectionsListComponent} from './poll-detail/poll-elections-list/poll-elections-list.component';
import {PollElectionItemComponent} from './poll-detail/poll-elections-list/poll-election-item/poll-election-item.component';
import {PollCorporateListComponent} from './poll-detail/poll-corporate-list/poll-corporate-list.component';
import {PollCorporateItemComponent} from './poll-detail/poll-corporate-list/poll-corporate-item/poll-corporate-item.component';
import {PollCorporateDetailsComponent} from './poll-detail/poll-corporate-details/poll-corporate-details.component';
import {PollCorporateDetailsItemComponent} from './poll-detail/poll-corporate-details/poll-corporate-details-item/poll-corporate-details-item.component';
import {ListResultsListComponent} from './poll-results/list-results-list/list-results-list.component';
import {PollResultsRatureResultComponent} from './poll-results/poll-results-rature-result/poll-results-rature-result.component';

import {environment} from '../environments/environment';
import { MoveNextByMaxLengthDirective } from './move-next-by-max-length.directive';
import { AssessorComponentComponent } from './assesor-component/assessor-component.component';
import { EventsListComponent } from './events-list/events-list.component';
import { EventItemComponent } from './events-list/event-item/event-item.component';

@NgModule({
  declarations: [
    AppComponent,
    PollChoiceComponent,
    PollDetailComponent,
    PollVoteComponent,
    PollResultsComponent,
    PollResultsListComponent,
    PollDetailListComponent,
    PollDetailResolutionsListComponent,
    PollResultsResolutionListComponent,
    PollResolutionItemComponent,
    PollResolutionChoicesListComponent,
    PollResolutionOptionComponent,
    PollChoiceFooterComponent,
    PollVoteFooterComponent,
    PollResultsResultComponent,
    PollVoteResolutionFooterComponent,
    PollResolutionVoteComponent,
    RecommendationsComponent,
    PollVoteWarningComponent,
    ErrorComponent,
    RecoveryComponent,
    DynamicDisplayComponent,
    WarningPopupComponent,
    AssessorsPopupComponent,
    PollDetailChoicePlurinominalComponent,
    MoveNextByMaxLengthDirective,
    VoteSubmitComponent,
    PollResultsHolderComponent,
    PollElectionsListComponent,
    PollElectionItemComponent,
    PollCorporateListComponent,
    PollCorporateItemComponent,
    PollCorporateDetailsComponent,
    PollCorporateDetailsItemComponent,
    ListResultsListComponent,
    PollResultsRatureResultComponent,
    AssessorComponentComponent,
    EventsListComponent,
    EventItemComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    NgbModule,
    V8teConfModule,
    AppRoutingModule,
    NgCircleProgressModule.forRoot({}),
    HttpClientModule,
    QRCodeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          // Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      }
    },
    I18nService
  ],
  bootstrap: [AppComponent]
})


export class AppModule {
  constructor(private i18nService: I18nService) {
  }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

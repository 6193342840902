import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Choice} from './../../shared/model/poll.model';

@Component({
  selector: 'app-poll-resolution-vote',
  templateUrl: 'poll-resolution-vote.component.html',
  styleUrls: ['./poll-resolution-vote.component.less']
})

export class PollResolutionVoteComponent implements OnInit {
  @Input() choice: Choice;
  @Input() isMobile: boolean;
  @Input() question: string;
  @Input() title: string;
  @Output()
  selected = new EventEmitter<string>();
  imageasset = 'assets/pouce_levee_resolution_blanc.svg';

  actionVote() {
    this.selected.emit('votedResolution');
  }


  ngOnInit() {
    switch (this.choice.text) {
      case 'Contre':
      case 'Against':
      case 'En contra': {
        this.imageasset = 'assets/pouce_baissee_orange.svg';
        break;
      }
      case 'Abstention':
      case 'Abstención': {
        this.imageasset = 'assets/abstention_resolution.svg';
        break;
      }
      default:
        this.imageasset = 'assets/pouce_levee_resolution.svg';
        break;
    }
  }
}

<div *ngIf="isMobile === false && shouldShow === true" class="poll-item" (click)=actionVote()>
  <div class="poll-item-description-holder">
    <span class="poll-item-question">
      {{choice.text === '' ? '?' : choice.text}}
    </span>
  </div>
</div>
<div *ngIf="isMobile === true && shouldShow === true" class="poll-item-mob" (click)=actionVote()>
  <div class="poll-item-description-holder-mob">
    <span class="poll-item-question">
      {{choice.text === '' ? '?' : choice.text}}
    </span>
  </div>
</div>

<div>
  <div id="separator"></div>
  <div *ngIf="isLive && activeResolution == -1" class="wait-text">
    {{'POLL-LIST.WAITING' | translate}}
  </div>
  <div *ngIf="isLive" class="button-refresh" (click)="refreshList()">
    <img src="assets/refresh_24px.svg">
    <span class="refresh-text">{{'POLL-LIST.REFRESH' | translate}}</span>
  </div>
</div>
<div *ngIf="show && !isLive" [ngClass]="isMobile == true ? 'resolutions-holder-mob' : 'resolutions-holder'"
  style="margin-bottom: 24px;">
  <div class="top-holder">
    <h3 id="resolutions-header">{{'POLL-DETAIL.RESOLUTIONS-TITLE' | translate}}</h3>
    <p class="resolutions-participation">
      <span class="participation-label">{{unansweredCount}}/{{resolutions.length}}</span>
      <progress max="100" value={{percentage}}> 70% </progress>
    </p>
  </div>
  <app-poll-resolution-item (selected)="setResolution($event)" *ngFor="let resolution of resolutions; let i = index"
    [index]="i" [votedData]="responses[i]" [isMobile]="isMobile" [resolution]="resolution" [resolutionImages]="resolutionImages"
    [images]="images">
  </app-poll-resolution-item>
</div>
<div *ngIf="show && isLive && activeResolution > -1" style="margin-bottom: 24px;"
  [ngClass]="isMobile == true ? 'resolutions-holder-mob' : 'resolutions-holder'">
  <h3 id="resolutions-header">{{'POLL-DETAIL.RESOLUTIONS-TITLE' | translate}}</h3>
  <app-poll-resolution-item (selected)="setResolution($event)" [index]="activeResolution" [images]="images"
    [votedData]="responses[0]" [isMobile]="isMobile" [resolution]="resolutions[0]"
    [resolutionImages]="resolutionImages">
  </app-poll-resolution-item>
</div>

<!-- <div style="text-align:center" [ngClass]="isMobile ? 'footer' : 'footer col-4'"> -->
<!-- <div [ngClass]="isMobile ? 'separator-mob' : 'separator'"></div> -->
<div style="text-align:center" [ngClass]="isMobile ? 'footer-confirm-mob' : 'footer-confirm'">
<div class="button-container button-container-mob">
  <button type="button" id="button-cancel" (click)="actionCancel()">
    {{'POLL-CHOICE.ACTION_CANCEL' | translate}}
  </button>

  <button *ngIf="validButton else notValid" type="button" id="button-vote" class="button-blue" (click)="actionVoted()">
    {{'POLL-CHOICE.FOOTER_CONFIRM' | translate}}
  </button>
  <ng-template #notValid>
    <button type="button" id="button-vote" class="button-disable">
      {{'POLL-CHOICE.FOOTER_CONFIRM' | translate}}
    </button>
  </ng-template>

  </div>
</div>

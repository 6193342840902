import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {Choice} from '../../../shared/model/poll.model';

@Component({
  selector: 'app-poll-resolution-option',
  templateUrl: './poll-resolution-option.component.html',
  styleUrls: ['./poll-resolution-option.component.less'],
})
export class PollResolutionOptionComponent implements OnInit {

  @Input() choice: Choice;
  @Input() isMobile: boolean;
  @Input() index: number;
  @Output() selected = new EventEmitter<Choice>();
  imageasset = 'assets/pouce_levee_resolution.svg';
  shouldShow = true;

  actionVote() {
      this.selected.emit(this.choice);
    }

  ngOnInit() {
    const languageKey = 'userLanguage';
    const userLang = navigator.language || navigator[languageKey];
    this.imageasset = 'assets/resolution_unselected.svg';
    switch (this.index) {
      case 0: {
        switch (userLang.substring(0, 2)) {
          case 'fr': { this.choice.text = 'Pour'; break; }
          case 'es': { this.choice.text = 'A favor'; break; }
          default: { this.choice.text = 'For'; }
        }
        break;
      }
      case 1: {
        switch (userLang.substring(0, 2)) {
          case 'fr': { this.choice.text = 'Contre'; break; }
          case 'es': { this.choice.text = 'En contra'; break; }
          default: { this.choice.text = 'Against'; }
        }
        break;
      }
      case 2: {
        switch (userLang.substring(0, 2)) {
          case 'es': { this.choice.text = 'Abstención'; break; }
          default: { this.choice.text = 'Abstention'; }
        }
        break;
      }
      case 3: {
        this.shouldShow = false;
        break;
      }
      default:
        break;
    }
  }
}

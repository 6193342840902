import { Component, Output, Input, EventEmitter, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-popup',
  templateUrl: 'warning-popup.component.html',
  styleUrls: ['./warning-popup.component.less', '../shared/common.less'],
})
export class WarningPopupComponent implements OnChanges, OnInit {
  @Output() payment = new EventEmitter<boolean>();
  @Input() paymentLine: boolean;
  @Input() modifiedLine: boolean;
  @Input() validateButton: boolean;
  @Input() cancelButton: boolean;
  @Input() callButton: boolean;
  @Input() modifyButton: boolean;
  @Input() confirmButton: boolean;
  @Input() understoodButton: boolean;
  @Input() votingAvailableButtons: boolean;
  @Input() phoneInput: boolean;
  @Input() firstText: string;
  @Input() title: string;
  @Input() secondText: string;
  @Input() thirdText: string;
  @Input() index: number;
  @Input() isAssoc: boolean;
  @Input() origin: string;
  @Input() isValidated: boolean;
  @Input() noMoreSMS = false;
  @Input() isValidationCodeError = false;
  @Input() phoneNumber: string;
  @Input() retries: number;

  @Output() contactPhone = new EventEmitter<string>();
  @Output() endDetailsPopup = new EventEmitter<boolean>();
  @Output() sendSMSEvent = new EventEmitter<boolean>();
  @Output() sendCodeEvent = new EventEmitter<string>();

  isPhoneValid = false;
  status: number;
  shouldCalculateError = false;

  launch() {
    this.payment.emit(true);
  }

  resendSMS() {
    this.sendSMSEvent.emit(true);
  }

  codeInput($event) {
    const alphanum = /^([0-9]|[a-z])+([0-9a-z]+)$/i;
    if ($event.key != null && !$event.key.match(alphanum)) {
      if ($event.target.id.localeCompare('letter-6') !== 0) {
        // setTimeout(function () {
        //   document.getElementById($event.srcElement.nextElementSibling.id).focus();
        // }, 0)
      }
    }
    if ($event.keyCode === 8) {
      if ($event.target.id.localeCompare('letter-1') !== 0) {
        setTimeout(() => {
            document.getElementById($event.srcElement.previousElementSibling.id).focus();
          }, 200);
      }
    }
    const scoppedThis = this;
    setTimeout(() => {
        scoppedThis.validate();
      }, 200);
}

  validate() {
    const letter1 = (document.getElementById('letter-1') as HTMLInputElement).value;
    const letter2 = (document.getElementById('letter-2') as HTMLInputElement).value;
    const letter3 = (document.getElementById('letter-3') as HTMLInputElement).value;
    const letter4 = (document.getElementById('letter-4') as HTMLInputElement).value;
    const letter5 = (document.getElementById('letter-5') as HTMLInputElement).value;
    const letter6 = (document.getElementById('letter-6') as HTMLInputElement).value;
    const confirmationCode = letter1 + letter2 + letter3 + letter4 + letter5 + letter6;
    if (confirmationCode.length === 6){
      this.sendCodeEvent.emit(confirmationCode);
    }
    this.shouldCalculateError = confirmationCode.length === 6;
  }

  clicAction($event) {
    if (
      $event.target.className === 'div-arriere-plan-action' ||
      $event.target.className === 'div-global-action'
    ) {
      this.cancelDetails(false);
    }
  }

  onPhoneEdit($event) {
    this.isPhoneValid = this.isValid($event.target.value);
  }

  isValid(p: string) {
    const phoneRe = /^\+?[\d]{0,15}$/;
    return phoneRe.test(p);
  }

  sendContactPhone() {
    const element = document.getElementById('phone-input') as HTMLInputElement;
    this.contactPhone.emit(element.value);
  }

  cancelDetails(value) {
    this.endDetailsPopup.emit(value);
  }

  cancel() {
    this.payment.emit(false);
  }

  replaceAt(src, index, replacement) {
    return src.substr(0, index) + replacement + src.substr(index + replacement.length);
  }

  ngOnChanges() {
    if (this.phoneNumber !== undefined && this.phoneNumber !== null) {
      this.phoneNumber = this.replaceAt(this.phoneNumber, 2, '******');
    }
  }

  ngOnInit() {
    if (this.phoneNumber !== undefined && this.phoneNumber !== null) {
      this.phoneNumber = this.replaceAt(this.phoneNumber, 2, '******');
    }
    if (this.origin === 'addVoters' || this.origin === 'addSMS') {
      this.status = 1;
    }
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-poll-vote-warning',
  templateUrl: 'poll-vote-warning.component.html',
  styleUrls: ['./poll-vote-warning.component.less', '../../shared/common.less']
})

export class PollVoteWarningComponent {
  @Input() isMobile: boolean;
  @Output() shouldVote = new EventEmitter<boolean>();

  vote() {
    this.shouldVote.emit(true);
  }

  cancel() {
    this.shouldVote.emit(false);
  }

  constructor() {
  }
}

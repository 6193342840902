<div id="page-container">
  <div class="headband">
  </div>
  <div id="content-wrap">
    <div class="left-container" *ngIf="!isMobile">
      <img src="{{pollImage}}" id="client-logo" alt="logo" *ngIf="pollImage.length > 1 else v8te"/>
      <ng-template #v8te>
        <a href="https://www.v8te.com"><img src="assets/v8te.png" id="logo" alt="logo"></a>
      </ng-template>
    </div>
    <div class="mid-container">
      <div class="wrapper">
        <div class="title-container">
          <h3 class="title">{{title}}</h3>
        </div>
        <div [ngClass]="isMobile ? 'infos-holder-mob' : 'infos-holder'">
          <div class="holder-voters">
            <circle-progress id="attendance" [percent]="participation" [radius]="21" [outerStrokeWidth]="4"
              [innerStrokeWidth]="4" [space]="-4" [outerStrokeColor]="'#1890FF'" [innerStrokeColor]="'#e7e8ea'"
              [titleFontSize]="14" [titleColor]="'#1890FF'" [unitsColor]="'#1890FF'" [unitsFontSize]="14"
              [showSubtitle]="false" [animation]="true" [animationDuration]="300" [startFromZero]="false">
            </circle-progress>
            <div class="descriptions-holder">
              <h3 class="info-black">{{votedNumber}}</h3>
              <h3 class="info-grey">sur {{votersNumber}}</h3>
            </div>
          </div>
          <div class="holder-voters" id="left-holder">
            <img class="calendar-image" src="assets/calendar.svg">
            <div class="descriptions-holder">
              <h3 class="info-grey">{{'POLL-DETAIL.CLOSING' | translate}}</h3>
              <h3 class="info-black">{{dateString}}</h3>
            </div>
          </div>
        </div>
        <h3 id="result-title">{{'POLL-RESULT.RESULTS' | translate}}</h3>
        <app-results-holder id="resultsPage" [pollId]="pollId" [blockchainUrl]="blockchainUrl">
        </app-results-holder>
      </div>
    </div>
    <div class="right_container"></div>
  </div>
</div>

import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {Resolution} from '../../shared/model/poll.model';

@Component({
  selector: 'app-poll-resolutions-list',
  templateUrl: './poll-detail-resolutions-list.component.html',
  styleUrls: ['./poll-detail-resolutions-list.component.less', '../../shared/common.less'],
})
export class PollDetailResolutionsListComponent implements OnInit {

  @Input() resolutions: Resolution[];
  @Input() question: string;
  @Input() isMobile: boolean;
  @Input() responses: number[][];
  @Input() isLive: boolean;
  @Input() activeResolution: number;
  @Input() resolutionImages: Array<string>;
  @Input() images: Array<string>;
  show = true;
  unansweredCount = 0;
  percentage = 0;
  @Output() selected = new EventEmitter<Resolution>();
  @Output() refresh = new EventEmitter<boolean>();

  setResolution(event: Resolution) {
    this.selected.emit(event);
    if (!this.isLive) {
      this.calculateUnanswered();
    }
  }

  calculateUnanswered() {
    for (const response of this.responses) {
      if (response[3] === 0) {
        this.unansweredCount = this.unansweredCount + 1;
      }
    }
    this.percentage = Math.round(this.unansweredCount / this.resolutions.length * 100);
  }

  refreshList() {
    this.refresh.emit(true);
  }

  ngOnInit() {
    this.calculateUnanswered();
  }
}

<p class="graph"><progress max="100" value={{result.percentage}}> 70% </progress></p>
<div class="flex-main">
  <span class="answer">{{result.text === '' ? '?' : choice}}</span>
  <div style="white-space: nowrap;">
    <span class="voters">{{result.votes === null ? '?' : result.votes}} {{'LISTS.RATURES' | translate}}</span>
    <span class="voters"> - </span>
    <span class="percentage">{{result.percentage === null ? '?' : result.percentage + "%"}}</span>
  </div>
</div>
<div class="spacer"></div>

import { Component, Output, Input, EventEmitter, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-assessors-popup',
  templateUrl: 'assessors-popup.component.html',
  styleUrls: ['./assessors-popup.component.less', '../shared/common.less'],
})
export class AssessorsPopupComponent {
  @Input() title: String
  @Input() description: String
}

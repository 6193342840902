<div class="poll-item" (click)=actionVote(false,$event)>
  <label class="resolution-label">
    <div id="number-holder">
      <div *ngIf="choice.image != null && choice.image.length > 0 && choice.image != 'null'" class="option-image-wrapper">
        <img id="resolution-{{index}}" [ngClass]="!isSelectedList ? 'option-image' : 'option-image image-selected'" src="{{choice.image}}" alt="{{choice.text}}">
        <div class="selected-list-image" *ngIf="isSelectedList">
          <div class="selected-image-round">
            <span class="selected-image-checkmark"></span>
          </div>
        </div>
      </div>
      <div *ngIf="choice.image == null || choice.image.length == 0 || choice.image == 'null'" [ngClass]="!isSelectedList ? 'circle-unanswered' : 'circle-selected'">
        <span [class]="!isSelectedList ? 'resolution-index' : 'resolution-index-selected'">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder">
      <span *ngIf="choice.link == undefined || choice.link.length == 0" class="poll-item-question">
        {{choice.text === '' ? '?' : choice.text}}
      </span>
      <span *ngIf="choice.link != undefined && choice.link.length > 0" class="poll-item-question">
        <a href="{{replaceURLs(choice.link)}}" target="_blank">{{choice.text === '' ? '?' : choice.text}}</a>
      </span>
    </div>
    <img *ngIf="!isFinal" src={{imageAsset}} class="helper-image">
    <div *ngIf="isFinal" [ngClass]="['checkbox' + ' checkbox-' + index]" style="margin-right: 7px">
      <input class="box" type="checkbox" id="{{'checkbox-vb-' + index}}" (click)=actionVote(true,$event) [checked]="this.isSelectedList && this.isFinal">
      <span class="checkmark"></span>
    </div>
  </label>
</div>

<div class="footer-validation-mob" [ngClass]="status == 'vote' ? 'footer-confirm' : 'footer-validation'">
  <div style="margin: auto;">
    <button *ngIf="twoButtons == true" type="button" id="button-cancel" (click)="actionCancel()">
      {{'POLL-CHOICE.ACTION_CANCEL' | translate}}
    </button>
  
    <button *ngIf="validButton && twoButtons == true else notValid" type="button" id="button-vote-list" class="button-blue" (click)="actionVoted()">
      {{'POLL-CHOICE.FOOTER_CONFIRM' | translate}}
    </button>
    <ng-template #notValid>
      <button *ngIf="twoButtons == true" type="button" id="button-vote-list" class="button-disable">
        {{'POLL-CHOICE.FOOTER_CONFIRM' | translate}}
      </button>
    </ng-template>


    <button *ngIf="twoButtons != true" type="button" id="button-vote" class="button-blue" (click)="actionVoted()">
      {{'POLL-CHOICE.ACTION_VOTE' | translate}}
    </button>
  </div>
</div>
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoDefaultComponent} from './video-default/video-default.component';
import {VideoZoomComponent} from './video-zoom/video-zoom.component';
import {VideoJitsiComponent} from './video-jitsi/video-jitsi.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {I18nService} from '../core/i18n.service';


@NgModule({
  declarations: [VideoDefaultComponent, VideoZoomComponent, VideoJitsiComponent],
  exports: [
    VideoDefaultComponent
  ],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [I18nService]
})
export class V8teConfModule {
  constructor(private i18nService: I18nService) {
  }
}




export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

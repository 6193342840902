<div style="text-align:center">
  <body *ngIf="isMobile === false" class="description-long">{{question === '' ? '?' : question}}</body>
  <body *ngIf="isMobile === true" class="description-long-mob">{{question === '' ? '?' : question}}</body>
</div>
<div *ngIf="isMobile === false" class="container poll-item mt-sm-2 mb-sm-2">
  <div class="poll-item-description-holder">
    <span class="poll-item-question">
      {{choice.text === '' ? '?' : choice.text}}
    </span>
  </div>
</div>
<div *ngIf="isMobile === true" class="container poll-item-mob mt-sm-2 mb-sm-2">
  <div class="poll-item-description-holder-mob">
    <span class="poll-item-question">
      {{choice.text === '' ? '?' : choice.text}}
    </span>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ApiService } from '../shared/api.service';
import { Common } from '../shared/common';
import { PollPreview, CodeResponse, AssessorInfo, AssessorParticipationResponse } from '../shared/model/poll.model';
import { Router, ActivatedRoute } from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-assesor-component',
  templateUrl: './assessor-component.component.html',
  styleUrls: ['./assessor-component.component.less', './../dynamic-display/dynamic-display.component.less', './../poll-detail/poll-detail.component.less', './../shared/common.less'],
})
export class AssessorComponentComponent implements OnInit {
  email: string;
  isMobile = false;
  electionDataJson: PollPreview;
  participation = 0;
  voterParticipation = 0;
  dateString: string;
  status = 1;
  shouldDisplayCodeError = false;
  invitation: string;
  urlCode = 'placeholder';
  inviteCode: string;
  isPollDeleted = false;
  closedPoll = false;
  previewLink = '';

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, public common: Common) { }

   codeInput($event) {
     const alphanum = /^([0-9]|[a-z])+([0-9a-z]+)$/i;
     if ($event.key != null && !$event.key.match(alphanum)) {
       if ($event.target.id.localeCompare('letter-6') !== 0) {
         // setTimeout(function () {
         //   document.getElementById($event.srcElement.nextElementSibling.id).focus();
         // }, 0)
       }
     }
     if ($event.keyCode === 8) {
       if ($event.target.id.localeCompare('letter-1') !== 0) {
         setTimeout(() => {
             document.getElementById($event.srcElement.previousElementSibling.id).focus();
           }, 200);
       }
     }
     const scoppedThis = this;
     setTimeout(() => {
         scoppedThis.validate();
       }, 200);
 }

   validate() {
     const letter1 = (document.getElementById('letter-1') as HTMLInputElement).value;
     const letter2 = (document.getElementById('letter-2') as HTMLInputElement).value;
     const letter3 = (document.getElementById('letter-3') as HTMLInputElement).value;
     const letter4 = (document.getElementById('letter-4') as HTMLInputElement).value;
     const letter5 = (document.getElementById('letter-5') as HTMLInputElement).value;
     const letter6 = (document.getElementById('letter-6') as HTMLInputElement).value;
     const confirmationCode = letter1 + letter2 + letter3 + letter4 + letter5 + letter6;
     if (confirmationCode.length === 6){
       !this.electionDataJson.opened ? this.postAssessors(confirmationCode) : this.postTally(confirmationCode);
     }
     this.shouldDisplayCodeError = this.shouldDisplayCodeError && confirmationCode.length === 6;
   }

   postAssessors(confirmationCode: string) {
     this.apiService.postAssessorValidation(confirmationCode, this.invitation).subscribe((res: AssessorParticipationResponse) => {
       if (res.assessors > 0) {
         this.electionDataJson.assessors = res.assessors;
         this.electionDataJson.validatedAssessors = res.validated;
         this.participation = Math.round((res.validated / res.assessors) * 100);
       }
       this.status = 2;
     },
     (err) => {
      this.shouldDisplayCodeError = true;
      this.clearFields();
     });
   }

   postTally(confirmationCode: string) {
     const pollId = this.router.url.split('/')[2];
     this.apiService.postAssessorTally(confirmationCode, pollId).subscribe((res: AssessorParticipationResponse) => {
       if (res.assessors > 0) {
         this.electionDataJson.assessors = res.assessors;
         this.electionDataJson.validatedAssessors = res.assessors;
         this.participation = Math.round((res.validated / this.electionDataJson.assessors) * 100);
       }
       this.status = 2;
     },
     (err) => {
      this.shouldDisplayCodeError = true;
      this.clearFields();
     });
   }


   clearFields() {
     (document.getElementById('letter-6') as HTMLInputElement).value = null;
     (document.getElementById('letter-5') as HTMLInputElement).value = null;
     (document.getElementById('letter-4') as HTMLInputElement).value = null;
     (document.getElementById('letter-3') as HTMLInputElement).value = null;
     (document.getElementById('letter-2') as HTMLInputElement).value = null;
     (document.getElementById('letter-1') as HTMLInputElement).value = null;
     (document.getElementById('letter-1') as HTMLInputElement).focus();
   }
  getUserLanguage(): string {
    const languageKey = 'userLanguage';
    return navigator.language || navigator[languageKey];
  }

  openPreview() {
    window.location.href = this.previewLink;
  }

  ngOnInit(): void {
    this.isMobile = this.common.checkIfMobile();
    if (this.getUserLanguage().localeCompare("fr-FR") == 0) {
      document.getElementsByTagName('title')[0].innerHTML = 'Confirmation assesseur';
    } else {
      document.getElementsByTagName('title')[0].innerHTML = 'Assessor confirmation';
    }
    const pollId = this.route.snapshot.params.id;
    this.apiService.getPollPreview(pollId).subscribe((res: PollPreview) => {
      this.electionDataJson = res;
      if (res.assessors > 0) {
        this.participation = Math.round(
                  (this.electionDataJson.validatedAssessors / this.electionDataJson.assessors) * 100
                );
      }
      const endDate = res.endDate;
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      this.dateString = new Date(endDate).toLocaleDateString('fr-FR', options);
      if (!res.opened) { this.status = 1; }
      if (res.opened && ! res.isFinished) { this.status = 3; }
      if (res.opened && res.isFinished ) { this.status = 4; }
      this.route.queryParams.subscribe(params => {
        this.invitation = params.invitation;
        this.previewLink = `${environment.settings.assessorValidation}/poll-planned-assessor/${pollId}/${this.invitation}`;

        this.apiService.getAssessorUrl(this.invitation).subscribe((code: CodeResponse) => {
          this.urlCode = code.url;
          this.inviteCode = code.code;
        });
        this.apiService.getAssesssorInfo(this.invitation).subscribe((info: AssessorInfo) => {
          this.email = info.name;
          if (info.used && !res.isFinished) { this.status = !res.opened ? 2 : 3; }
          if (res.isTallied) { this.status = 5; }
          if (this.status >= 3) {
              this.voterParticipation = Math.round((this.electionDataJson.votes / this.electionDataJson.voters) * 100);
          }
        });
        if (this.getUserLanguage().localeCompare("fr-FR") == 0) {
          document.getElementsByTagName('title')[0].innerHTML = 'Confirmation assesseur'
        } else {
          document.getElementsByTagName("title")[0].innerHTML = "Assessor confirmation"
        }

      });
    }, (err) => {
      console.log(err.status);

      if (err.status === 404) {
        this.closedPoll = true;
      }
      if (err.status === 410) {
        this.isPollDeleted = true
      }
    });
  }

}

<div class="separator"></div>

<!-- <div style="text-align:center" class="footer"> -->
<div class="footer-choice">
  <div class="center">
    <button type="button" id="button-vote" class="button-blue" (click)="actionVote()">
      {{'POLL-CHOICE.FOOTER_CONFIRM' | translate}}
    </button>
  </div>
</div>

<div *ngFor="let answer of parentResult.answers; let i = index">
  <div class="top-line"></div>
  <div class="question-header">
    <h2 class="index-number">{{i+1}}</h2>
    <div class="second-flex">
      <h5 class="headline">{{answer === '' ? '?' : common.localizeAnswer(answer)}}</h5>
      <h5 class="headline" style="font-weight: bold; white-space: nowrap;">{{safeNumber(parentResult.results[i].percentage).toLocaleString()}} %</h5>
    </div>
  </div>
  <app-poll-results-rature-result
    *ngFor="let child of getChildren(i, parentResult.results[i])"
    [result]="child">
  </app-poll-results-rature-result>
</div>

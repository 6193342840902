import {AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LazyLoadingLibsService} from "../services/lazy-loading-libs.service";
import {DOCUMENT} from "@angular/common";
import {LazyLoadingObject} from "../services/lazy-loading-object";

@Component({
  selector: 'app-video-jitsi',
  templateUrl: './video-jitsi.component.html',
  styleUrls: ['./video-jitsi.component.css']
})
export class VideoJitsiComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * Objet représentant la librairie à charger.
   */
  private libraryToLoad: LazyLoadingObject = {
    name: 'jitsi',
    scripts: [
      'https://meet.jit.si/external_api.js'
    ],
    styles: []
  }

  @ViewChild('jitsiContainer') jitsiContainer: ElementRef;
  isLoading: boolean = true;

  constructor(private readonly lazyLoadingLibsService: LazyLoadingLibsService,
              @Inject(DOCUMENT) private readonly document: any) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.lazyLoadingLibsService.lazyLoadLibrary(this.libraryToLoad)
      .subscribe(_ => {
        this.initJitsiMeetVideoConf();
      });
  }

  ngOnDestroy(): void {
    this.isLoading = true;
    // On supprime les données du container
    this.jitsiContainer.nativeElement.innerHTML = "";
  }

  /**
   * Fonction pour initialiser une conférence
   * @private
   */
  private initJitsiMeetVideoConf() {
    const domain = 'meet.jit.si';
    const options = {
      // roomName: 'v8teAppTest',
      width: '100%',
      height: '100%',
      parentNode: this.jitsiContainer.nativeElement,
      onload: () => {
        console.log('Chargement Jitsi OK');
        this.isLoading = false;
      }
    };
    new JitsiMeetExternalAPI(domain, options);
  }
}

export declare class JitsiMeetExternalAPI {
  constructor(domain: any, options: any);
}



<div class="poll-item" (click)=selectEvent()>
  <label>
    <div id="number-holder">
      <div *ngIf='!votedStatus' [ngClass]="isUnanswered ? 'circle-unanswered' : 'circle'">
        <span class="resolution-index">{{index+1}}</span>
      </div>
      <img *ngIf="votedStatus" class="voted-checkbox" src="assets/voted-check-circle.svg">
    </div>
    <div [class]="isMobile ? 'poll-item-description-holder' : 'poll-item-description-holder-mob'">
      <span class="poll-item-question">
        {{round.title === '' ? '?' : round.title}}
      </span>
    </div>
    <img *ngIf="!votedStatus" src={{imageAsset}} class="helper-image">
    <div *ngIf="votedStatus" style="width: 24px; margin-right: 15px;"></div>
  </label>
</div>

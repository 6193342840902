<div class="container-fluid">

  <div class="row align-self-start justify-content-center zoom-loading animated fadeIn" [hidden]="!isloading">
    <div class="col-12">
      <!-- Spinner de chargement -->
      <div class="v8te-spinner  align-self-start">
        <div class="v8te-spinner-dot"></div>
        <div class="v8te-spinner-dot"></div>
        <div class="v8te-spinner-dot"></div>
        <div class="v8te-spinner-dot"></div>
        <div class="v8te-spinner-dot"></div>
        <div class="v8te-spinner-dot"></div>
      </div>
    </div>
  </div>

  <!-- -------------------------------------------------------- -->
  <!-- Formulaire pour la saisie des informations de conférence -->
  <div class="row align-self-start justify-content-center zoom-form animated fadeIn" [ngClass]="{'display' : isError}" [hidden]="isloading || isDisplayMeeting">
    <div class="col-8">
      <!-- Material form group -->
      <form>

        <div class="md-form form-group mt-8">
          <input type="text" [(ngModel)]="zoomMeetingInfo.meetingNumber" name="meetingNumber" class="form-control" id="meetingNumber" placeholder="numéro de la conférence à 10 chiffres et sans espace">
        </div>

        <div class="md-form form-group mt-8">
          <input [(ngModel)]="zoomMeetingInfo.meetingPassword" name="meetingPassword"
                 type="password"
                 class="form-control"
                 id="meetingPassword"
                 placeholder="mot de passe de la conférence">
        </div>

        <div class="md-form form-group mt-8">
          <input [(ngModel)]="zoomMeetingInfo.userName" name="userName"
                 type="text"
                 class="form-control"
                 id="userName"
                 placeholder="nom d'utilisateur">
        </div>

        <!-- Grid row -->
        <button type="submit" mdbBtn color="primary" (click)="startZoomMeeting()">Rejoindre le meeting</button>
      </form>
    </div>
  </div>

  <!-- -------------------------------------------------- -->
  <!--  Container dans lequel sera affichée la conférence.
        !! Attention : Lors du chargement de la Zoom, une 'div' #zmmtg-root est ajouté directement dans le body du DOM HTML.
        On le masque par défaut via CSS dans le index.html.
        Une fois que le composant Zoom doit etre affiché, on récupère l'élement  HTML et on le déplace dans le container ci-dessous.
  -->
  <div class="row zoom-parent-container align-self-start animated fadeIn" [hidden]="!isDisplayMeeting">
    <div #zoomContainer class="col-12">

    </div>
  </div>
</div>

import { Component, Input, Output, EventEmitter } from '@angular/core';
import {Choice} from '../shared/model/poll.model';

@Component({
  selector: 'app-poll-vote',
  templateUrl: 'poll-vote.component.html',
  styleUrls: ['./poll-vote.component.less']
})

export class PollVoteComponent {
  @Input() choice: Choice;
  @Input() isMobile: boolean;
  @Input() question: string;
  @Output() selected = new EventEmitter<string>();

  actionVote() {
    this.selected.emit('voted');
  }
}

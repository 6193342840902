export enum StatusesWithoutBackButton {
  list = 'list',
  validatedList = 'validatedList',
  voteFailed = 'voteFailed',
  alreadyVoted = 'alreadyVoted',
  resolutionWarning = 'resolutionWarning',
  completed = 'completed',
  voted = 'voted',
  electionsList = 'electionsList',
  resolutonDetails = 'resolutonDetails',
  voteResolution = 'voteResolution',
  listVote = 'listVote',
  listSelected = 'listSelected',
  eventList = 'eventList'
}

export enum StatusesThatShouldNotUseLongLine {
  list = 'list',
  validatedList = 'validatedList',
  voted = 'voted',
  voteFailed = 'voteFailed',
  resolutionWarning = 'resolutionWarning',
  completed = 'completed',
  electionsList = 'electionsList',
  listVote = 'listVote',
  listSelected = 'listSelected'
}

export enum StatusesThatShouldDisplayElectionTitle {
  list = 'list',
  validatedList = 'validatedList',
  resolutionWarning = 'resolutionWarning',
  completed = 'completed',
  electionsList = 'electionsList',
  listVote = 'listVote',
  listSelected = 'listSelected',
  eventList = 'eventList'
}

export enum StatusesWithoutConfirmVote {
  electionsList = 'electionsList',
  voteFailed = 'voteFailed',
  voted = 'voted',
  completed = 'completed',
  listVote = 'listVote',
  listSelected = 'listSelected'
}

class ErrorModel {
  title: string;
  canRetry: boolean;
  description: string;
  constructor(title: string, canRetry: boolean, description: string) {
    this.title = title;
    this.canRetry = canRetry;
    this.description = description;
  }
}

export class ErrorList {
  closed: ErrorModel;
  retry: ErrorModel;
  voted: ErrorModel;
  blankVote: ErrorModel;
  votedAt: ErrorModel;
  constructor(closed: ErrorModel, retry: ErrorModel, voted: ErrorModel, blankVote: ErrorModel, votedAt: ErrorModel) {
    this.closed = closed;
    this.retry = retry;
    this.voted = voted;
    this.blankVote = blankVote;
    this.votedAt = votedAt;
  }
}

export const votingErrors = {
  fr: new ErrorList(
    new ErrorModel('Scrutin clôturé', false, 'votre bulletin ne peut plus être déposé dans l’urne'),
    new ErrorModel('Veuillez recommencer', true, 'votre bulletin de vote n’a pu être déposé dans l’urne'),
    new ErrorModel('Vous avez déjà voté.', false, 'votre bulletin de vote a déjà été déposé dans l’urne'),
    new ErrorModel('Le Vote blanc est exclusif.', true, 'Veuillez recommencer en actualisant votre page'),
    new ErrorModel('Vous avez déjà voté.', false, 'votre bulletin de vote a déjà été déposé dans l’urne le ')
  ),
  en: new ErrorList(
    new ErrorModel('Closed poll', false, 'Your ballot can no longer be placed in the ballot box'),
    new ErrorModel('Would you do it again?', true, 'your ballot is not in the box yet'),
    new ErrorModel('You have already voted', false, 'your ballot has already been deposited in the ballot box'),
     new ErrorModel('Blank vote is exclusive.', true, 'Please start over by refreshing your page'),
    new ErrorModel('You have already voted', false, 'your ballot has already been deposited in the ballot box at')
  ),
  es: new ErrorList(
    new ErrorModel('Elección terminada', false, 'Su boleta ya no puede ser depositada en la urna'),
    new ErrorModel('¿Lo haría de nuevo?', true, 'Su boleta aún no está en la urna'),
    new ErrorModel('Ya ha votado', false, 'Su boleta ya ha sido depositada en la urna'),
    new ErrorModel('El Voto en blanco es exclusivo', true, 'Por favor, comience de nuevo actualizando la página'),
    new ErrorModel('Ya ha votado', false, 'Su boleta ya ha sido depositada en la urna el ')
  )
};

<app-warning-popup *ngIf="isValidated == false && userRetriesRemaining >= 0" [isValidated]="isValidated" title="{{'2FA.CODE' | translate}}"
  (sendCodeEvent)="validate($event)" (sendSMSEvent)="resendSMS()" [phoneNumber]="userPhoneNumber"
  [retries]="userRetriesRemaining" [isValidationCodeError]="isValidationCodeError" [noMoreSMS]="noMoreSMS">
</app-warning-popup>
<app-warning-popup *ngIf="isValidated == false && userRetriesRemaining < 0" [isValidated]="isValidated" title="{{'2FA.CODE-PERSONAL' | translate}}"
  (sendCodeEvent)="validate($event)" (sendSMSEvent)="resendSMS()" [phoneNumber]="userPhoneNumber"
  [retries]="userRetriesRemaining" [isValidationCodeError]="isValidationCodeError" [noMoreSMS]="noMoreSMS">
</app-warning-popup>
<div *ngIf="isValidated == true" id="page-container">
  <script async type="text/javascript" src="./blue-vote-crypto_js-jsdeps.min.js"></script>
  <script async type="text/javascript" src="./blue-vote-crypto_js-opt.js"></script>
  <div id="content-wrap">
    <div *ngIf="shouldDisplayBackButton()" class="fullScreen">
      <div class="left-header" (click)="actionBack()">
        <img src="assets/back.svg" class="backbutton">
      </div>
      <div class="right-header">
        <h3 *ngIf="status === statuses.validation || status === statuses.vote" class="title">
          {{'POLL-VOTE.CHOICE-TITLE' | translate}}
        </h3>
        <h3 *ngIf="status === statuses.multielectionRoundSelected" style="margin: 0px" class="title">
          {{electionDataJson != null ? electionDataJson.name : ""}}
        </h3>
      </div>
    </div>
    <div *ngIf="status === statuses.resolutonDetails || status === statuses.voteResolution" class="top-smaller">
      <div class="left-header" (click)="actionBack()">
        <img src="assets/back.svg" class="backbutton">
      </div>
      <div class="right-header">
        <h3 class="title">
          {{selectedResolution.text}}
        </h3>
      </div>
    </div>
    <!-- <div *ngIf="shouldUseLongSeparator()" class="separator-large"></div> -->
    <div  class="summary">
      <div *ngIf="shouldDisplayElectionTitle()">
        <div *ngIf="status === statuses.listSelected || plurionominalShouldAddBack()" style="display: flex; flex-direction: row; align-items: center; margin-top: 20px;">
          <div *ngIf="electionDataJson != null && electionDataJson.opened" class="left-header" (click)="actionBack()">
            <img src="assets/back.svg" class="backbutton" style="transform: translate(0%, 0%);">
          </div>
          <div class="right-header">
            <h3 class="title">{{getHeaderTitle()}}</h3>
          </div>
        </div>
        <h3 *ngIf="status !== statuses.listSelected && !plurionominalShouldAddBack() && electionDataJson != null" class="question">
          <!-- {{votingType == 'liveCandidates' ? titleList[liveActiveResolution] : title}} -->
          {{getHeaderTitle()}}
        </h3>
        <div *ngIf="electionDataJson !== null" class="secret-container">
          <img src="assets/info_icon.svg" alt="alert" class="icon">
          <p *ngIf="electionDataJson.publicVote" class="is-secret">{{'POLL-DETAIL.PUBLIC' | translate}}</p>
          <p *ngIf="!electionDataJson.publicVote" class="is-secret">{{'POLL-DETAIL.SECRET' | translate}}</p>
        </div>
        <div id="firstLine" *ngIf="electionDataJson != null && electionDataJson.description != ' '">
          <p class="description-long" [innerHTML] = "electionDataJson != null ? common.replaceURLs(electionDataJson.description) : ''"></p>
          <div *ngIf="electionDataJson != null && electionDataJson.sharedLinks != null">
            <p class="description-black">
              <span id="document-span">{{'POLL-DETAIL.LINK' | translate}}</span><a [innerHTML]="common.replaceURLs(electionDataJson.sharedLinks)" style="display: block; overflow: hidden; text-overflow: ellipsis;">{{electionDataJson.sharedLinks}}</a>
            </p>
          </div>
        </div>
        <a class="blockchain-link" href="{{blockchainUrl}}" target="_blank" rel="noopener noreferrer" *ngIf="votingType == 'list' && status !== statuses.eventList">
          <img alt="globe" src="assets/global.svg" /> {{'POLL-DETAIL.POLL-BLOCKCHAIN' | translate}}
        </a>
        <div class="infos-holder">
          <div *ngIf="electionDataJson != null && electionDataJson.publicVote" class="holder-voters">
            <circle-progress id="attendance" [percent]="participation" [radius]="21" [outerStrokeWidth]="4"
              [innerStrokeWidth]="4" [space]="-4" [outerStrokeColor]="'#1890FF'" [innerStrokeColor]="'#e7e8ea'"
              [titleFontSize]="14" [titleColor]="'#1890FF'" [unitsColor]="'#1890FF'" [unitsFontSize]="14"
              [showSubtitle]="false" [animation]="true" [animationDuration]="300" [startFromZero]="false">
            </circle-progress>
            <div class="descriptions-holder">
              <h3 class="info-black">{{electionDataJson != null ? electionDataJson.votes : ""}}</h3>
              <h3 class="info-grey">sur {{electionDataJson != null ? electionDataJson.voters: ""}}</h3>
            </div>
          </div>
          <div class="holder-voters" id="left-holder">
            <img class="calendar-image" src="assets/calendar.svg">
            <div class="descriptions-holder">
              <h3 class="info-grey">{{'POLL-DETAIL.CLOSING' | translate}}</h3>
              <h3 class="info-black">{{dateString}}</h3>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="status === statuses.voted || status === statuses.voteFailed || status === statuses.alreadyVoted">
        <div *ngIf="isMultiCorporate() && !hasVotedOnEveryRound()" style="display: flex; flex-direction: row; margin-top: 24px;">
          <div class="left-header" (click)="actionBack()">
            <img src="assets/back.svg" class="backbutton" style="transform: translate(0%, 0%);">
          </div>
          <div class="right-header">
            <h3 class="title">{{electionDataJson != null ? electionDataJson.name : ""}}</h3>
          </div>
        </div>
        <h3 *ngIf="!isMultiCorporate() || hasVotedOnEveryRound()" class="voted-title">
          {{ electionDataJson == null ? '?' : electionDataJson.name === '' ? '?' : electionDataJson.name }}
        </h3>
        <div class="secret-container">
          <img src="assets/info_icon.svg" alt="alert" class="icon">
          <p *ngIf="electionDataJson.publicVote" class="is-secret">{{'POLL-DETAIL.PUBLIC' | translate}}</p>
          <p *ngIf="!electionDataJson.publicVote" class="is-secret">{{'POLL-DETAIL.SECRET' | translate}}</p>
        </div>
        <a class="blockchain-link" href="{{blockchainUrl}}" *ngIf="votingType == 'list'"><img alt="globe" src="assets/global.svg" /> {{'POLL-DETAIL.POLL-BLOCKCHAIN' | translate}}</a>
        <div class="infos-holder">
          <div *ngIf="electionDataJson.publicVote" class="holder-voters">
            <circle-progress id="attendance" [percent]="participation" [radius]="21" [outerStrokeWidth]="4"
              [innerStrokeWidth]="4" [space]="-4" [outerStrokeColor]="'#1890FF'" [innerStrokeColor]="'#e7e8ea'"
              [titleFontSize]="14" [titleColor]="'#1890FF'" [unitsColor]="'#1890FF'" [unitsFontSize]="14"
              [showSubtitle]="false" [animation]="true" [animationDuration]="300" [startFromZero]="false">
            </circle-progress>
            <div class="descriptions-holder">
              <h3 class="info-black">{{ electionDataJson == null ? 0 : electionDataJson.votes}}</h3>
              <h3 class="info-grey">sur {{ electionDataJson == null ? 0 : electionDataJson.voters}}</h3>
            </div>
          </div>
          <div class="holder-voters" id="left-holder">
            <img class="calendar-image" src="assets/calendar.svg">
            <div class="descriptions-holder">
              <h3 class="info-grey">{{'POLL-DETAIL.CLOSING' | translate}}</h3>
              <h3 class="info-black">{{dateString}}</h3>
            </div>
          </div>
        </div>
        <div class="separator"></div>
      </div>
      <div *ngIf="titleList[liveActiveResolution] != null && indexes[liveActiveResolution] != null">
        <!-- Election candidates -->
        <app-poll-detail-list *ngIf="electionDataJson != null && (status === statuses.list ||
          status === statuses.multielectionRoundSelected) && isResolution === false" class="election"
          (plurinominal)="plurinominalSelection($event)" (unselected)="plurinominalUnselection($event)"
          [electionTitle]="titleList[liveActiveResolution]" [liveActiveResolution]="liveActiveResolution" [previousVotes]="indexes[liveActiveResolution]"
          [numberOfVote]="numberOfVote[liveActiveResolution]" [votes]="indexes[liveActiveResolution].length" [validPlurinominal]="validPlurinominal"
          [isMobile]="isMobile" [isLive]="isLive" (refresh)="refreshPoll()" [choices]="choices[liveActiveResolution]"
          (simpleSelect)="selection($event)" [liveSubjectLink]="liveSubjectLink" [displayedCandidates]="activePoll.displayedCandidates">
        </app-poll-detail-list>
      </div>
      <div *ngIf="titleList[liveActiveResolution] == null || indexes[liveActiveResolution] == null">
        <app-poll-detail-list *ngIf="electionDataJson != null && (status === statuses.list || status ===
          statuses.multielectionRoundSelected) && isResolution === false" class="list"
          [liveActiveResolution]="liveActiveResolution" [previousVotes]="[]" [numberOfVote]="numberOfVote[liveActiveResolution]"
          [isMobile]="isMobile" [isLive]="isLive" (refresh)="refreshPoll()" [choices]="choices[liveActiveResolution]"
          (simpleSelect)="selection($event)">
        </app-poll-detail-list>
      </div>
      <div *ngIf="status == statuses.voted && votingType == 'list' && electionDataJson.rounds.length > 1 && getNextVotingRound() >= 0" style="margin: 20px auto; display: flex">
        <button type="button" style="margin: auto" class="button-blue" (click)="goToNextEvent()">
          {{'POLL-VOTE.NEXT-EVENT' | translate}}
        </button>
      </div>
      <app-poll-corporate-list *ngIf="status === statuses.listVote" [isMobile]="isMobile" [choices]="choices[liveActiveResolution]"
        (sendListSelection)="setActiveList($event)" [selectedIndex]="getSelectedList()" [displayElectionName]="this.electionDataJson.rounds.length == 1"
        [electionTitle]="this.electionDataJson.rounds[0].title">
      </app-poll-corporate-list>
      <app-events-list *ngIf="status === statuses.eventList" [isMobile]="isMobile" [event]="electionDataJson" [votedStatuses]="votedStatuses" (selected)="eventSelection($event)">
      </app-events-list>
      <app-poll-corporate-details *ngIf="status === statuses.listSelected" [isMobile]="isMobile" [questions]="getListItems()"
        [electionTitle]="activePoll.displayedQuestions[0].answers[liveActiveResolution]"
        (sendListSelection)="setListAnswered($event)" (sendListCancel)="sendListCancel()" [answers]="getAnswersForList()">
      </app-poll-corporate-details>
      <!-- Elections name -->
      <app-elections-list *ngIf="status === statuses.electionsList && electionDataJson != null" [elections]="activePoll.displayedQuestions"
        [isMobile]="isMobile" [responses]="indexes" (selected)=setActiveElection($event)>
      </app-elections-list>
      <app-poll-resolutions-list *ngIf="(status === statuses.list || status == statuses.validatedList) && isResolution === true"
        (selected)="resolutionPreselected($event)" (refresh)="refreshPoll()" [isMobile]="isMobile"
        [isLive]="isLive" [activeResolution]="liveActiveResolution" [resolutions]="resolutions" [responses]="responses"
        [resolutionImages]="resolutionImages" [images]="resolutionImagesDownloaded">
      </app-poll-resolutions-list>
      <app-poll-resolution-choices-list *ngIf="status === statuses.resolutonDetails"
        (selected)="resolutionChoicePreselected($event)" [isMobile]="isMobile"
        [choices]="selectedResolution.choices">
      </app-poll-resolution-choices-list>
      <app-poll-choice *ngIf="status === statuses.validation" (selected)="choiceConfirmed()"
        [question]="electionDataJson.description" [isMobile]="isMobile" [choice]="selectedChoice">
      </app-poll-choice>
      <app-poll-resolution-option *ngIf="status === statuses.validationResolution"
        (selected)="choiceConfirmedResolution()" [isMobile]="isMobile" [choice]="selectedChoice">
      </app-poll-resolution-option>
      <app-poll-vote *ngIf="status === statuses.vote" (selected)="choiceVote($event)" [isMobile]="isMobile"
        [question]="electionDataJson.description" [choice]="selectedChoice">
      </app-poll-vote>
      <app-poll-resolution-vote *ngIf="status === statuses.voteResolution" (selected)="setResolutionChoice($event)"
        [question]="electionDataJson.description" [isMobile]="isMobile" [choice]="selectedChoice">
      </app-poll-resolution-vote>

      <!-- New Confirmation card for laptop -->
      <app-vote-submit-confirmation *ngIf="status === statuses.voted && (votingType == 'list' && electionDataJson.rounds.length > 1 && getNextVotingRound() == -1)" title="{{'POLL-VOTED.HEADER-TITLE-COMPLETE' | translate}}"
      description="{{'POLL-VOTED.HEADER-DESCRIPTION-COMPLETE' | translate}}" [isMobile]="isMobile"
      (shouldRetry)="refreshPoll()" [isError]="false" [scheduled]="isLive">
      </app-vote-submit-confirmation>
      <app-vote-submit-confirmation *ngIf="status === statuses.voted && !(votingType == 'list' && electionDataJson.rounds.length > 1 && getNextVotingRound() == -1)" title="{{'POLL-VOTED.HEADER_TITLE' | translate}}"
      description="{{'POLL-VOTED.HEADER_DESCRIPTION' | translate}}" [isMobile]="isMobile"
      (shouldRetry)="refreshPoll()" [isError]="false" [scheduled]="isLive">
      </app-vote-submit-confirmation>
      <app-vote-submit-confirmation *ngIf="status === statuses.alreadyVoted || status === statuses.voteFailed" [title]="errorTitle" [description]="errorMessage"
      [isMobile]="isMobile" (shouldRetry)="retry()" [isError]="true" [scheduled]="isLive">
      </app-vote-submit-confirmation>
      <div class="hash-container" *ngIf="(status === statuses.voted || status === statuses.alreadyVoted) && (votingType == 'list' || isCryptoCandidates()) && votingProof != null">
        <h1 class="hash-title">{{'BLOCKCHAIN.TITLE' | translate}}</h1>
        <p class="hash-text">{{'BLOCKCHAIN.TEXT' | translate}}</p>
        <p class="hash"><b>{{voteHash}}</b></p>
      </div>

      <app-poll-vote-warning *ngIf="status === statuses.incomplete" [title]="errorTitle">
      </app-poll-vote-warning>
      <app-warning-popup class="resolutionWarning" *ngIf="status === statuses.resolutionWarning" title="{{'POLL-WARNING.ELECTION-AVAILABLE' | translate}}"
        [votingAvailableButtons]="true" (endDetailsPopup)="handleWarning($event)"></app-warning-popup>
      <router-outlet></router-outlet>

    </div>
    <app-poll-choice-footer *ngIf="status === statuses.validation || status == statuses.validationResolution"
      (selected)="choiceConfirmed()" [isMobile]="isMobile">
    </app-poll-choice-footer>
    <!-- This footer is correct -->
    <app-poll-vote-footer *ngIf="status === statuses.vote || status == statuses.validatedList"
      (selected)="choiceVote($event)" [status]="status">
    </app-poll-vote-footer>
    <app-poll-vote-resolution-footer *ngIf="status === statuses.voteResolution" (selected)="setResolutionChoice($event)"
      [isMobile]="isMobile">
    </app-poll-vote-resolution-footer>
  </div>
  <app-warning-popup *ngIf="plurinominalWarning == 'incomplete'" title="{{'POLL-WARNING.CATCH' | translate}}"
    firstText="{{'PLURINOMINAL.REMAINING' | translate}} {{numberOfVote[liveActiveResolution] - indexes[liveActiveResolution].length}} {{'PLURINOMINAL.CONFIRM-VOTE' | translate}}"
    [confirmButton]="true" [modifyButton]="true" (endDetailsPopup)="handlePopupReturn($event)">
  </app-warning-popup>
  <app-warning-popup *ngIf="plurinominalWarning == 'multiElection'" class="missing-election"
    title="{{'POLL-WARNING.ELECTION-AVAILABLE' | translate}}" [votingAvailableButtons]="true" (endDetailsPopup)="handlePopupReturn($event)">
  </app-warning-popup>

  <div *ngIf="numberOfVote[liveActiveResolution] > 1" id="snackbar">
    <div class="snackbar-wrapper">
      <p style="margin:0px">{{'PLURINOMINAL.VOTE-LIMIT' | translate}} {{numberOfVote[liveActiveResolution]}} {{'PLURINOMINAL.VOTES' | translate}}</p>
    </div>
  </div>

  <div *ngIf="numberOfVote[liveActiveResolution] > 1 && indexes[liveActiveResolution].length  > 0 &&
    status != statuses.voted && status != statuses.voteFailed  && status !== statuses.completed &&
    status != statuses.electionsList" class="button-holder-bottom">
      <button *ngIf="status != statuses.multielectionRoundSelected && !minVoteLimitNotAchieved()" type="button" id="button-vote" class="button-blue 1" (click)="validatePlurinominal()">
        {{'POLL-CHOICE.ACTION_VOTE' | translate}}
      </button>
      <button *ngIf="status === statuses.multielectionRoundSelected && !minVoteLimitNotAchieved()" type="button" id="button-vote"
      class="button-blue 2" (click)="validatePlurinominal()">
        {{'POLL-CHOICE.ROUND-VOTE' | translate}}
      </button>
      <button *ngIf="minVoteLimitNotAchieved()" class="button-invalid">
        {{'POLL-CHOICE.UNDER-MINIMUM' | translate}}{{getMinimButtonCounter()}}
      </button>
  </div>

  <div *ngIf="electionDataJson != null && numberOfVote[liveActiveResolution] == 1 && indexes[liveActiveResolution] != null && indexes[liveActiveResolution].length  > 0  && !shouldNotDisplayElectionTitle() && status != statuses.eventList"
    class="button-holder-bottom">
      <button *ngIf="status != statuses.multielectionRoundSelected && status != statuses.voteFailed" type="button" id="button-vote"
      class="button-blue 3" (click)="votingPopup()">
        {{'POLL-CHOICE.ACTION_VOTE' | translate}}
      </button>
      <button *ngIf="status === statuses.multielectionRoundSelected" type="button" id="button-vote" class="button-blue 4"
      (click)="electionRoundVote()">
        {{'POLL-CHOICE.ROUND-VOTE' | translate}}
      </button>
  </div>

  <div *ngIf="status == statuses.electionsList && answeredCount > 0"
    class="button-holder-bottom">
      <button type="button" id="button-vote" class="button-blue 5" (click)="validateMultielection()">
        {{'POLL-CHOICE.ACTION_VOTE' | translate}}
      </button>
  </div>

  <div *ngIf="status == statuses.listVote && getListCompletion()"
       class="button-holder-bottom">
      <button type="button" id="button-vote" class="button-blue 5" (click)="votingPopup()">
        {{'POLL-CHOICE.ACTION_VOTE' | translate}}
      </button>
  </div>

  <div *ngIf="status === statuses.completed" class="finished-lifter">
    <div class="separator"></div>
    <h3 class="question" style="margin-top: 30px;">
      {{'POLL-DETAIL.FINISHED-TITLE' | translate}}
    </h3>
  </div>
  <app-warning-popup  *ngIf="validateVotePopup" title="{{'POLL-WARNING.CATCH' | translate}}"
    firstText="{{'POLL-WARNING.TITLE' | translate}}" [confirmButton]="true" [modifyButton]="true"
    (endDetailsPopup)="validateVote($event)"> </app-warning-popup>

  <div *ngIf="status == statuses.voted && votingType == 'list' && electionDataJson.rounds.length > 1 && getNextVotingRound() >= 0" class="button-holder-bottom">
    <button type="button" id="button-vote" class="button-blue" (click)="goToNextEvent()">
      {{'POLL-VOTE.NEXT-EVENT' | translate}}
    </button>
  </div>

  <div *ngIf="isVotingAnimation">
    <app-vote-animation [isMobile]="isMobile">
    </app-vote-animation>
  </div>
</div>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vote-animation',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.less']
})
export class RecommendationsComponent {
  @Input() isMobile: boolean;

}

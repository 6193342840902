import {Component, Input, ViewChild} from '@angular/core';
import {VideoZoomComponent} from "../video-zoom/video-zoom.component";
import {VideoJitsiComponent} from "../video-jitsi/video-jitsi.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-video-default',
  templateUrl: './video-default.component.html',
  styleUrls: ['./video-default.component.less'],
  animations: [
    // animation triggers go here
  ]
})
export class VideoDefaultComponent {
  @Input() isLive: boolean;
  public isDisplayConf: boolean = false;
  public isDisplayZoomApp: boolean = false;
  public isDisplayJitsiApp: boolean = false;

  @ViewChild(VideoZoomComponent, {static: false}) zoomComponentRef: VideoZoomComponent;
  @ViewChild(VideoJitsiComponent, {static: false}) jitsiComponentRef: VideoJitsiComponent;
  private readonly onDestroy: Subject<any> = new Subject<any>();
  v8te: string;

  constructor() { }

  /**
   * Focntion qui permet de détruire / ré-initialiser les composant enfants quand on fait un 'reset'
   */
  destroyChild() {
    // On cache les vidéo
    this.hideAllVideo();

    // On détruit les composants enfants.
    if (this.zoomComponentRef) {
      this.zoomComponentRef.ngOnDestroy();
    }

    if (this.jitsiComponentRef) {
      this.jitsiComponentRef.ngOnDestroy();
    }

    let footer = document.getElementsByClassName("footer");
    (footer[0] as HTMLElement).style.left = null
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.destroyChild();
  }

  displayZoomVideo() {
    this.isDisplayJitsiApp = false;
    this.isDisplayConf = true;
    this.isDisplayZoomApp = true;

    let container = document.getElementsByClassName("container-fluid");
    (container[0] as HTMLElement).style.gridTemplateColumns = "33% 67%";

    console.log('display zoom');
    console.log('jitsi=' + this.isDisplayJitsiApp + ' - zoom=' + this.isDisplayZoomApp + ' -- ' + this.isDisplayConf);
  }

  displayJitsiVideo() {
    this.isDisplayZoomApp = false;
    this.isDisplayConf = true;
    this.isDisplayJitsiApp = true;

    let container = document.getElementsByClassName("container-fluid");
    (container[0] as HTMLElement).style.gridTemplateColumns = "33% 67%";

    console.log('display jitsi');
    console.log('jitsi=' + this.isDisplayJitsiApp + ' - zoom=' + this.isDisplayZoomApp + ' -- ' + this.isDisplayConf);
  }

  hideAllVideo() {
    this.isDisplayJitsiApp = false;
    this.isDisplayZoomApp = false;
    this.isDisplayConf = false;

    let container = document.getElementsByClassName("container-fluid");
    (container[0] as HTMLElement).style.gridTemplateColumns = "repeat(3, 1fr)";
  }

  ngOnChanges() {
  }

  displayVisio() {
    let elem = document.getElementById("visioServices");
      if (elem.classList.contains('show')) {
        elem.classList.remove('show');
      } else {
        elem.classList.add('show');
      }
  }

  ngOnInit() {
    var userLang = navigator.language || navigator["userLanguage"];
          if (userLang.substring(0, 2) != "fr") {
            this.v8te = "www.v8te.com/";
          } else {
            this.v8te = "www.v8te.com/fr";
          }
  }
}
